define("@zestia/ember-select-box/utils/registration/input", ["exports", "@zestia/ember-select-box/utils/select-box/role"], function (_exports, _role) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerInput = registerInput;
  _exports.deregisterInput = deregisterInput;

  function registerInput(selectBox, input) {
    (false && !(!selectBox.input) && Ember.assert('select-box can only have 1 input', !selectBox.input));
    selectBox.input = input;
    Ember.run.scheduleOnce('afterRender', selectBox, _role.configureAsCombobox, selectBox);
  }

  function deregisterInput(selectBox, input) {
    selectBox.input = null;
    Ember.run.scheduleOnce('afterRender', selectBox, _role.configureAsListbox, selectBox);
  }
});