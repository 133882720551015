define("ember-table/-private/utils/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.objectAt = objectAt;
  _exports.splice = splice;
  _exports.move = move;

  /**
    Genericizes `objectAt` so it can be run against a normal array or an Ember array
  
    @param {object|Array} arr
    @param {number} index
    @return {any}
  */
  function objectAt(arr, index) {
    (false && !(Ember.isArray(arr) || typeof arr.objectAt === 'function') && Ember.assert('arr must be an instance of a Javascript Array or implement `objectAt`', Ember.isArray(arr) || typeof arr.objectAt === 'function'));

    if (typeof arr.objectAt === 'function') {
      return arr.objectAt(index);
    }

    return arr[index];
  }

  function splice(items, start, count, ...add) {
    if (typeof items.replace === 'function' && typeof items.objectAt === 'function') {
      return items.replace(start, count, add);
    }

    return items.splice(start, count, ...add);
  }
  /**
   * Cycle shift an internal [start..end] to [start + 1...end, start].
   */


  function move(items, start, end) {
    var sourceItem = objectAt(items, start);
    splice(items, start, 1);
    splice(items, end, 0, sourceItem);
  }
});