define("@zestia/ember-select-box/utils/select-box/option/select", ["exports", "@zestia/ember-select-box/utils/component/invoke-action", "@zestia/ember-select-box/utils/select-box/value"], function (_exports, _invokeAction, _value) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._selectOption = _selectOption;
  _exports.selectOption = selectOption;
  _exports.selectedOption = selectedOption;

  function _selectOption(option) {
    if (option.isDisabled) {
      return;
    }

    return (0, _invokeAction.default)(option, '_onSelect', option);
  }

  function selectOption(selectBox, option) {
    selectedOption(option);
    return (0, _value.selectValue)(selectBox, option.value);
  }

  function selectedOption(option) {
    (0, _invokeAction.default)(option, 'onSelect', option.value, option.args.selectBox.api);
  }
});