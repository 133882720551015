define("@glimmer/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertNever = assertNever;
  _exports.assert = debugAssert;
  _exports.deprecate = deprecate;
  _exports.dict = dict;
  _exports.isDict = isDict;
  _exports.isObject = isObject;
  _exports.ensureGuid = ensureGuid;
  _exports.initializeGuid = initializeGuid;
  _exports.isSerializationFirstNode = isSerializationFirstNode;
  _exports.assign = assign;
  _exports.fillNulls = fillNulls;
  _exports.values = values;
  _exports.clearElement = clearElement;
  _exports.keys = keys;
  _exports.unwrap = unwrap;
  _exports.expect = expect;
  _exports.unreachable = unreachable;
  _exports.exhausted = exhausted;
  _exports.strip = strip;
  _exports.encodeImmediate = encodeImmediate;
  _exports.decodeImmediate = decodeImmediate;
  _exports.isSmallInt = isSmallInt;
  _exports.isHandle = isHandle;
  _exports.encodeHandle = encodeHandle;
  _exports.decodeHandle = decodeHandle;
  _exports.unwrapHandle = unwrapHandle;
  _exports.unwrapTemplate = unwrapTemplate;
  _exports.extractHandle = extractHandle;
  _exports.isOkHandle = isOkHandle;
  _exports.isErrHandle = isErrHandle;
  _exports.symbol = _exports.tuple = _exports.debugToString = _exports.ListSlice = _exports.ListNode = _exports.LinkedList = _exports.EMPTY_SLICE = _exports.SERIALIZATION_FIRST_NODE_STRING = _exports.Stack = _exports.DictSet = _exports.EMPTY_ARRAY = void 0;
  var EMPTY_ARRAY = Object.freeze([]); // import Logger from './logger';
  // let alreadyWarned = false;

  _exports.EMPTY_ARRAY = EMPTY_ARRAY;

  function debugAssert(test, msg) {
    // if (!alreadyWarned) {
    //   alreadyWarned = true;
    //   Logger.warn("Don't leave debug assertions on in public builds");
    // }
    if (!test) {
      throw new Error(msg || 'assertion failure');
    }
  }

  function deprecate(desc) {
    console.warn("DEPRECATION: " + desc);
  }

  var GUID = 0;

  function initializeGuid(object) {
    return object._guid = ++GUID;
  }

  function ensureGuid(object) {
    return object._guid || initializeGuid(object);
  }

  function dict() {
    return Object.create(null);
  }

  function isDict(u) {
    return u !== null && u !== undefined;
  }

  function isObject(u) {
    return typeof u === 'object' && u !== null;
  }

  class DictSet {
    constructor() {
      this.dict = dict();
    }

    add(obj) {
      if (typeof obj === 'string') this.dict[obj] = obj;else this.dict[ensureGuid(obj)] = obj;
      return this;
    }

    delete(obj) {
      if (typeof obj === 'string') delete this.dict[obj];else if (obj._guid) delete this.dict[obj._guid];
    }

  }

  _exports.DictSet = DictSet;

  class StackImpl {
    constructor() {
      this.stack = [];
      this.current = null;
    }

    get size() {
      return this.stack.length;
    }

    push(item) {
      this.current = item;
      this.stack.push(item);
    }

    pop() {
      var item = this.stack.pop();
      var len = this.stack.length;
      this.current = len === 0 ? null : this.stack[len - 1];
      return item === undefined ? null : item;
    }

    nth(from) {
      var len = this.stack.length;
      return len < from ? null : this.stack[len - from];
    }

    isEmpty() {
      return this.stack.length === 0;
    }

    toArray() {
      return this.stack;
    }

  }

  _exports.Stack = StackImpl;

  function clearElement(parent) {
    var current = parent.firstChild;

    while (current) {
      var next = current.nextSibling;
      parent.removeChild(current);
      current = next;
    }
  }

  var SERIALIZATION_FIRST_NODE_STRING = '%+b:0%';
  _exports.SERIALIZATION_FIRST_NODE_STRING = SERIALIZATION_FIRST_NODE_STRING;

  function isSerializationFirstNode(node) {
    return node.nodeValue === SERIALIZATION_FIRST_NODE_STRING;
  }

  class ListNode {
    constructor(value) {
      this.next = null;
      this.prev = null;
      this.value = value;
    }

  }

  _exports.ListNode = ListNode;

  class LinkedList {
    constructor() {
      this.clear();
    }

    head() {
      return this._head;
    }

    tail() {
      return this._tail;
    }

    clear() {
      this._head = this._tail = null;
    }

    toArray() {
      var out = [];
      this.forEachNode(n => out.push(n));
      return out;
    }

    nextNode(node) {
      return node.next;
    }

    forEachNode(callback) {
      var node = this._head;

      while (node !== null) {
        callback(node);
        node = node.next;
      }
    }

    insertBefore(node, reference = null) {
      if (reference === null) return this.append(node);
      if (reference.prev) reference.prev.next = node;else this._head = node;
      node.prev = reference.prev;
      node.next = reference;
      reference.prev = node;
      return node;
    }

    append(node) {
      var tail = this._tail;

      if (tail) {
        tail.next = node;
        node.prev = tail;
        node.next = null;
      } else {
        this._head = node;
      }

      return this._tail = node;
    }

    remove(node) {
      if (node.prev) node.prev.next = node.next;else this._head = node.next;
      if (node.next) node.next.prev = node.prev;else this._tail = node.prev;
      return node;
    }

  }

  _exports.LinkedList = LinkedList;

  class ListSlice {
    constructor(head, tail) {
      this._head = head;
      this._tail = tail;
    }

    forEachNode(callback) {
      var node = this._head;

      while (node !== null) {
        callback(node);
        node = this.nextNode(node);
      }
    }

    head() {
      return this._head;
    }

    tail() {
      return this._tail;
    }

    toArray() {
      var out = [];
      this.forEachNode(n => out.push(n));
      return out;
    }

    nextNode(node) {
      if (node === this._tail) return null;
      return node.next;
    }

  }

  _exports.ListSlice = ListSlice;
  var EMPTY_SLICE = new ListSlice(null, null);
  _exports.EMPTY_SLICE = EMPTY_SLICE;
  var {
    keys: objKeys
  } = Object;

  function assign(obj) {
    for (var i = 1; i < arguments.length; i++) {
      var assignment = arguments[i];
      if (assignment === null || typeof assignment !== 'object') continue;

      var _keys = objKeys(assignment);

      for (var j = 0; j < _keys.length; j++) {
        var key = _keys[j];
        obj[key] = assignment[key];
      }
    }

    return obj;
  }

  function fillNulls(count) {
    var arr = new Array(count);

    for (var i = 0; i < count; i++) {
      arr[i] = null;
    }

    return arr;
  }

  function values(obj) {
    var vals = [];

    for (var key in obj) {
      vals.push(obj[key]);
    }

    return vals;
  }

  function keys(obj) {
    return Object.keys(obj);
  }

  function unwrap(val) {
    if (val === null || val === undefined) throw new Error("Expected value to be present");
    return val;
  }

  function expect(val, message) {
    if (val === null || val === undefined) throw new Error(message);
    return val;
  }

  function unreachable(message = 'unreachable') {
    return new Error(message);
  }

  function exhausted(value) {
    throw new Error("Exhausted " + value);
  }

  var tuple = (...args) => args;

  _exports.tuple = tuple;
  var symbol = typeof Symbol !== 'undefined' ? Symbol : key => "__" + key + Math.floor(Math.random() * Date.now()) + "__";
  _exports.symbol = symbol;

  function strip(strings, ...args) {
    var out = '';

    for (var i = 0; i < strings.length; i++) {
      var string = strings[i];
      var dynamic = args[i] !== undefined ? String(args[i]) : '';
      out += "" + string + dynamic;
    }

    var lines = out.split('\n');

    while (lines.length && lines[0].match(/^\s*$/)) {
      lines.shift();
    }

    while (lines.length && lines[lines.length - 1].match(/^\s*$/)) {
      lines.pop();
    }

    var min = Infinity;

    for (var line of lines) {
      var leading = line.match(/^\s*/)[0].length;
      min = Math.min(min, leading);
    }

    var stripped = [];

    for (var _line of lines) {
      stripped.push(_line.slice(min));
    }

    return stripped.join('\n');
  }
  /**
   * Encodes a value that can be stored directly instead of being a handle.
   *
   * Immediates use the positive half of 32bits
   *
   * @param value - the value to be encoded.
   */


  function encodeImmediate(value) {
    if (typeof value === 'number') {
      // 1073741827 - (-1) == 1073741828
      // 1073741827 - (-1073741820) == 2147483647
      // positive it stays as is
      // 0 - 1073741823
      return value < 0 ? 1073741827
      /* NEGATIVE_BASE */
      - value : value;
    }

    if (value === false) {
      return 1073741824
      /* FALSE */
      ;
    }

    if (value === true) {
      return 1073741825
      /* TRUE */
      ;
    }

    if (value === null) {
      return 1073741826
      /* NULL */
      ;
    }

    if (value === undefined) {
      return 1073741827
      /* UNDEFINED */
      ;
    }

    return exhausted(value);
  }
  /**
   * Decodes an immediate into its value.
   *
   * @param value - the encoded immediate value
   */


  function decodeImmediate(value) {
    if (value > 1073741823
    /* MAX_INT */
    ) {
        switch (value) {
          case 1073741824
          /* FALSE */
          :
            return false;

          case 1073741825
          /* TRUE */
          :
            return true;

          case 1073741826
          /* NULL */
          :
            return null;

          case 1073741827
          /* UNDEFINED */
          :
            return undefined;

          default:
            // map 1073741828 to 2147483647 to -1 to -1073741820
            // 1073741827 - 1073741828 == -1
            // 1073741827 - 2147483647 == -1073741820
            return 1073741827
            /* NEGATIVE_BASE */
            - value;
        }
      }

    return value;
  }
  /**
   * True if the number can be stored directly or false if it needs a handle.
   *
   * This is used on any number type to see if it can be directly encoded.
   */


  function isSmallInt(num) {
    return isInt(num, -1073741820
    /* MIN_INT */
    , 1073741823
    /* MAX_INT */
    );
  }
  /**
   * True if the encoded int32 operand or encoded stack int32 is a handle.
   */


  function isHandle(encoded) {
    return encoded < 0;
  }
  /**
   * Encodes an index to an operand or stack handle.
   */


  function encodeHandle(index, maxIndex = 2147483647
  /* MAX_INDEX */
  , maxHandle = -1
  /* MAX_HANDLE */
  ) {
    if (index > maxIndex) {
      throw new Error("index " + index + " overflowed range 0 to " + maxIndex);
    } // -1 - 0 == -1
    // -1 - 1073741823 == -1073741824
    // -1073741825 - 0 == -1073741825
    // -1073741825 - 1073741823 == -2147483648


    return maxHandle - index;
  }
  /**
   * Decodes the index from the specified operand or stack handle.
   */


  function decodeHandle(handle, maxHandle = -1
  /* MAX_HANDLE */
  ) {
    // -1 - -1073741824 == 1073741823
    // -1073741825 - -1073741825 == 0
    // -1073741825 - -2147483648 == 1073741823
    return maxHandle - handle;
  }

  function isInt(num, min, max) {
    // this is the same as Math.floor(num) === num
    // also NaN % 1 is NaN and Infinity % 1 is NaN so both should fail
    return num % 1 === 0 && num >= min && num <= max;
  }

  function unwrapHandle(handle) {
    if (typeof handle === 'number') {
      return handle;
    } else {
      var error = handle.errors[0];
      throw new Error("Compile Error: " + error.problem + " @ " + error.span.start + ".." + error.span.end);
    }
  }

  function unwrapTemplate(template) {
    if (template.result === 'error') {
      throw new Error("Compile Error: " + template.problem + " @ " + template.span.start + ".." + template.span.end);
    }

    return template;
  }

  function extractHandle(handle) {
    if (typeof handle === 'number') {
      return handle;
    } else {
      return handle.handle;
    }
  }

  function isOkHandle(handle) {
    return typeof handle === 'number';
  }

  function isErrHandle(handle) {
    return typeof handle === 'number';
  }

  var debugToString;

  if (false
  /* DEBUG */
  ) {
    var getFunctionName = fn => {
      var functionName = fn.name;

      if (functionName === undefined) {
        var match = Function.prototype.toString.call(fn).match(/function (\w+)\s*\(/);
        functionName = match && match[1] || '';
      }

      return functionName.replace(/^bound /, '');
    };

    var getObjectName = obj => {
      var name;
      var className;

      if (obj.constructor && obj.constructor !== Object) {
        className = getFunctionName(obj.constructor);
      }

      if ('toString' in obj && obj.toString !== Object.prototype.toString && obj.toString !== Function.prototype.toString) {
        name = obj.toString();
      } // If the class has a decent looking name, and the `toString` is one of the
      // default Ember toStrings, replace the constructor portion of the toString
      // with the class name. We check the length of the class name to prevent doing
      // this when the value is minified.


      if (name && name.match(/<.*:ember\d+>/) && className && className[0] !== '_' && className.length > 2 && className !== 'Class') {
        return name.replace(/<.*:/, "<" + className + ":");
      }

      return name || className;
    };

    var getPrimitiveName = value => {
      return String(value);
    };

    debugToString = value => {
      if (typeof value === 'function') {
        return getFunctionName(value) || "(unknown function)";
      } else if (typeof value === 'object' && value !== null) {
        return getObjectName(value) || "(unknown object)";
      } else {
        return getPrimitiveName(value);
      }
    };
  }

  var debugToString$1 = debugToString;
  _exports.debugToString = debugToString$1;

  function assertNever(value, desc = 'unexpected unreachable branch') {
    console.log('unreachable', value);
    console.trace(desc + " :: " + JSON.stringify(value) + " (" + value + ")");
  }
});