define("@zestia/ember-select-box/utils/select-box/selected-option/activate", ["exports", "@zestia/ember-select-box/utils/component/invoke-action", "@zestia/ember-select-box/utils/component/scroll-into-view"], function (_exports, _invokeAction, _scrollIntoView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._activateSelectedOption = _activateSelectedOption;
  _exports.activateSelectedOption = activateSelectedOption;
  _exports.activateSelectedOptionAtIndex = activateSelectedOptionAtIndex;
  _exports.activateNextSelectedOption = activateNextSelectedOption;
  _exports.activatePreviousSelectedOption = activatePreviousSelectedOption;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _activateSelectedOption(selectedOption) {
    (0, _invokeAction.default)(selectedOption, '_onActivate', selectedOption);
  }

  function activatedSelectedOption(selectedOption) {
    (0, _invokeAction.default)(selectedOption, 'onActivate', selectedOption.value, selectedOption.args.selectBox.api);
  }

  function activateSelectedOption(selectBox, selectedOption, config) {
    activateSelectedOptionAtIndex(selectBox, selectedOption.index, config);
  }

  function setActiveSelectedOptionIndex(selectBox, index) {
    var under = index < 0;
    var over = index > selectBox.selectedOptions.length - 1;

    if (under || over) {
      return;
    }

    selectBox.activeSelectedOptionIndex = index;
  }

  function activateSelectedOptionAtIndex(selectBox, index, config) {
    setActiveSelectedOptionIndex(selectBox, index);
    var selectedOption = selectBox.activeSelectedOption;

    if (!selectedOption) {
      return;
    }

    (0, _scrollIntoView.maybeScrollIntoView)(selectedOption, config);
    activatedSelectedOption(selectedOption);
  }

  function activateNextSelectedOption(selectBox, config) {
    activateSelectedOptionAtIndex(selectBox, selectBox.activeSelectedOptionIndex + 1, _objectSpread({
      scrollIntoView: false
    }, config));
  }

  function activatePreviousSelectedOption(selectBox, config) {
    activateSelectedOptionAtIndex(selectBox, selectBox.activeSelectedOptionIndex - 1, _objectSpread({
      scrollIntoView: false
    }, config));
  }
});