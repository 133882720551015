define("@zestia/ember-select-box/utils/component/invoke-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = invokeAction;

  function invokeAction(component, name, ...args) {
    var func = component.args[name];

    if (typeof func === 'function') {
      return func.apply(component, args);
    }
  }
});