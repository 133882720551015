define("@zestia/ember-select-box/utils/shared/ready", ["exports", "@zestia/ember-select-box/utils/component/invoke-action"], function (_exports, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ready = ready;

  function ready(selectBox) {
    (0, _invokeAction.default)(selectBox, 'onReady', selectBox.api);
  }
});