define("@zestia/ember-select-box/utils/registration/options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerOptionsContainer = registerOptionsContainer;
  _exports.deregisterOptionsContainer = deregisterOptionsContainer;

  function registerOptionsContainer(selectBox, optionsContainer) {
    (false && !(!selectBox.optionsContainer) && Ember.assert('A select box can only have 1 options container', !selectBox.optionsContainer));
    selectBox.optionsContainer = optionsContainer;
  }

  function deregisterOptionsContainer(selectBox, optionsContainer) {
    selectBox.optionsContainer = null;
  }
});