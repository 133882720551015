define("ember-power-calendar/templates/components/power-calendar/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l1YvHKgX",
    "block": "{\"symbols\":[\"@calendar\",\"&default\"],\"statements\":[[10,\"nav\"],[14,0,\"ember-power-calendar-nav\"],[12],[2,\"\\n\"],[6,[37,4],[[32,1,[\"actions\",\"changeCenter\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"ember-power-calendar-nav-control ember-power-calendar-nav-control--previous\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[35,3,[\"actions\",\"moveCenter\"]],-1,[32,0,[\"unit\"]],[32,1]],null]],null],[12],[2,\"«\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"ember-power-calendar-nav-title\"],[12],[2,\"\\n\"],[6,[37,4],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,2],[[32,1,[\"center\"]],[32,0,[\"format\"]]],[[\"locale\"],[[32,1,[\"locale\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,4],[[35,3,[\"actions\",\"changeCenter\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"ember-power-calendar-nav-control ember-power-calendar-nav-control--next\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,1,[\"actions\",\"moveCenter\"]],1,[32,0,[\"unit\"]],[32,1]],null]],null],[12],[2,\"»\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"power-calendar-format-date\",\"calendar\",\"if\"]}",
    "meta": {
      "moduleName": "ember-power-calendar/templates/components/power-calendar/nav.hbs"
    }
  });

  _exports.default = _default;
});