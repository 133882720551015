define("ember-basic-dropdown/components/basic-dropdown-content", ["exports", "@glimmer/component", "ember-basic-dropdown/utils/calculate-position", "ember-basic-dropdown/utils/scroll-helpers"], function (_exports, _component, _calculatePosition, _scrollHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BasicDropdownContent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = (_temp = class BasicDropdownContent extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "transitioningInClass", this.args.transitioningInClass || 'ember-basic-dropdown--transitioning-in');

      _defineProperty(this, "transitionedInClass", this.args.transitionedInClass || 'ember-basic-dropdown--transitioned-in');

      _defineProperty(this, "transitioningOutClass", this.args.transitioningOutClass || 'ember-basic-dropdown--transitioning-out');

      _defineProperty(this, "isTouchDevice", this.args.isTouchDevice || Boolean(!!window && 'ontouchstart' in window));

      _defineProperty(this, "dropdownId", "ember-basic-dropdown-content-".concat(this.args.dropdown.uniqueId));

      _defineProperty(this, "hasMoved", false);

      _defineProperty(this, "handleRootMouseDown", void 0);

      _defineProperty(this, "scrollableAncestors", []);

      _defineProperty(this, "mutationObserver", void 0);

      _initializerDefineProperty(this, "animationClass", _descriptor, this);
    }

    get destinationElement() {
      return document.getElementById(this.args.destination);
    }

    get animationEnabled() {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.environment !== 'test';
    }

    get style() {
      var style = '';
      var {
        top,
        left,
        right,
        width,
        height,
        otherStyles
      } = this.args;

      if (otherStyles !== undefined) {
        for (var attr in otherStyles) {
          var value = otherStyles[attr];
          style += "".concat(attr, ": ").concat(value, ";");
        }
      }

      if (top) {
        style += "top: ".concat(top, ";");
      }

      if (left) {
        style += "left: ".concat(left, ";");
      }

      if (right) {
        style += "right: ".concat(right, ";");
      }

      if (width) {
        style += "width: ".concat(width, ";");
      }

      if (height) {
        style += "height: ".concat(height);
      }

      return Ember.String.htmlSafe(style);
    }
    /**
     * Allows similair behaviour to `ember-composable-helpers`' `optional` helper.
     * Avoids adding extra dependencies.
     * Can be removed when the template `V1` compatability event handlers are removed.
     *
     * @see https://github.com/cibernox/ember-basic-dropdown/issues/498
     * @memberof BasicDropdownContent
     */


    noop() {}

    setup(dropdownElement) {
      var triggerElement = document.querySelector("[data-ebd-id=".concat(this.args.dropdown.uniqueId, "-trigger]"));

      this.handleRootMouseDown = e => {
        if (e.target === null) return;
        var target = e.target;

        if (this.hasMoved || dropdownElement.contains(target) || triggerElement && triggerElement.contains(target)) {
          this.hasMoved = false;
          return;
        }

        if (dropdownIsValidParent(target, this.dropdownId)) {
          this.hasMoved = false;
          return;
        }

        this.args.dropdown.actions.close(e, true);
      };

      document.addEventListener(this.args.rootEventType, this.handleRootMouseDown, true);
      window.addEventListener('resize', this.runloopAwareReposition);
      window.addEventListener('orientationchange', this.runloopAwareReposition);

      if (this.isTouchDevice) {
        document.addEventListener('touchstart', this.touchStartHandler, true);
        document.addEventListener('touchend', this.handleRootMouseDown, true);
      }

      if (triggerElement !== null) {
        this.scrollableAncestors = getScrollableAncestors(triggerElement);
      }

      this.addScrollHandling(dropdownElement);
    }

    teardown() {
      this.removeGlobalEvents();
      this.removeScrollHandling();
      this.scrollableAncestors = [];
      document.removeEventListener(this.args.rootEventType, this.handleRootMouseDown, true);

      if (this.isTouchDevice) {
        document.removeEventListener('touchstart', this.touchStartHandler, true);
        document.removeEventListener('touchend', this.handleRootMouseDown, true);
      }
    }

    animateIn(dropdownElement) {
      if (!this.animationEnabled) return;
      waitForAnimations(dropdownElement, () => {
        this.animationClass = this.transitionedInClass;
      });
    }

    animateOut(dropdownElement) {
      if (!this.animationEnabled) return;
      var parentElement = dropdownElement.parentElement;
      if (parentElement === null) return;

      if (this.args.renderInPlace) {
        parentElement = parentElement.parentElement;
      }

      if (parentElement === null) return;
      var clone = dropdownElement.cloneNode(true);
      clone.id = "".concat(clone.id, "--clone");
      clone.classList.remove(...this.transitioningInClass.split(' '));
      clone.classList.add(...this.transitioningOutClass.split(' '));
      parentElement.appendChild(clone);
      this.animationClass = this.transitionedInClass;
      waitForAnimations(clone, function () {
        parentElement.removeChild(clone);
      });
    }

    setupMutationObserver(dropdownElement) {
      this.mutationObserver = new MutationObserver(mutations => {
        var shouldReposition = false;
        shouldReposition = Array.prototype.slice.call(mutations[0].addedNodes).some(node => {
          return node.nodeName !== '#comment' && !(node.nodeName === '#text' && node.nodeValue === '');
        });
        shouldReposition = shouldReposition || Array.prototype.slice.call(mutations[0].removedNodes).some(node => {
          return node.nodeName !== '#comment' && !(node.nodeName === '#text' && node.nodeValue === '');
        });

        if (shouldReposition && this.args.shouldReposition) {
          shouldReposition = this.args.shouldReposition(mutations, this.args.dropdown);
        }

        if (shouldReposition) {
          this.runloopAwareReposition();
        }
      });
      this.mutationObserver.observe(dropdownElement, {
        childList: true,
        subtree: true
      });
    }

    teardownMutationObserver() {
      if (this.mutationObserver !== undefined) {
        this.mutationObserver.disconnect();
        this.mutationObserver = undefined;
      }
    }

    touchStartHandler() {
      document.addEventListener('touchmove', this.touchMoveHandler, true);
    }

    touchMoveHandler() {
      this.hasMoved = true;
      document.removeEventListener('touchmove', this.touchMoveHandler, true);
    }

    runloopAwareReposition() {
      Ember.run.join(this.args.dropdown.actions.reposition);
    }

    removeGlobalEvents() {
      window.removeEventListener('resize', this.runloopAwareReposition);
      window.removeEventListener('orientationchange', this.runloopAwareReposition);
    } // Methods


    addScrollHandling(dropdownElement) {
      if (this.args.preventScroll === true) {
        var wheelHandler = event => {
          if (event.target === null) return;
          var target = event.target;

          if (dropdownElement.contains(target) || dropdownElement === event.target) {
            // Discover the amount of scrollable canvas that is within the dropdown.
            var availableScroll = (0, _scrollHelpers.getAvailableScroll)(target, dropdownElement); // Calculate what the event's desired change to that scrollable canvas is.

            var {
              deltaX,
              deltaY
            } = (0, _scrollHelpers.getScrollDeltas)(event); // If the consequence of the wheel action would result in scrolling beyond
            // the scrollable canvas of the dropdown, call preventDefault() and clamp
            // the value of the delta to the available scroll size.

            if (deltaX < availableScroll.deltaXNegative) {
              deltaX = availableScroll.deltaXNegative;
              event.preventDefault();
            } else if (deltaX > availableScroll.deltaXPositive) {
              deltaX = availableScroll.deltaXPositive;
              event.preventDefault();
            } else if (deltaY < availableScroll.deltaYNegative) {
              deltaY = availableScroll.deltaYNegative;
              event.preventDefault();
            } else if (deltaY > availableScroll.deltaYPositive) {
              deltaY = availableScroll.deltaYPositive;
              event.preventDefault();
            } // Add back in the default behavior for the two good states that the above
            // `preventDefault()` code will break.
            // - Two-axis scrolling on a one-axis scroll container
            // - The last relevant wheel event if the scroll is overshooting
            // Also, don't attempt to do this if both of `deltaX` or `deltaY` are 0.


            if (event.defaultPrevented && (deltaX || deltaY)) {
              (0, _scrollHelpers.distributeScroll)(deltaX, deltaY, target, dropdownElement);
            }
          } else {
            // Scrolling outside of the dropdown is prohibited.
            event.preventDefault();
          }
        };

        document.addEventListener('wheel', wheelHandler, {
          capture: true,
          passive: false
        });

        this.removeScrollHandling = () => {
          document.removeEventListener('wheel', wheelHandler, {
            capture: true
          });
        };
      } else {
        this.addScrollEvents();
        this.removeScrollHandling = this.removeScrollEvents;
      }
    } // Assigned at runtime to ensure that changes to the `preventScroll` property
    // don't result in not cleaning up after ourselves.


    removeScrollHandling() {} // These two functions wire up scroll handling if `preventScroll` is false.
    // These trigger reposition of the dropdown.


    addScrollEvents() {
      window.addEventListener('scroll', this.runloopAwareReposition);
      this.scrollableAncestors.forEach(el => {
        el.addEventListener('scroll', this.runloopAwareReposition);
      });
    }

    removeScrollEvents() {
      window.removeEventListener('scroll', this.runloopAwareReposition);
      this.scrollableAncestors.forEach(el => {
        el.removeEventListener('scroll', this.runloopAwareReposition);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "animationClass", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.animationEnabled ? this.transitioningInClass : '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardown", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "teardown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "animateIn", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "animateIn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "animateOut", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "animateOut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupMutationObserver", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setupMutationObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardownMutationObserver", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "teardownMutationObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "touchStartHandler", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "touchStartHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "touchMoveHandler", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "touchMoveHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "runloopAwareReposition", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "runloopAwareReposition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeGlobalEvents", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "removeGlobalEvents"), _class.prototype)), _class));
  _exports.default = BasicDropdownContent;

  // All ancestors with scroll (except the BODY, which is treated differently)
  function getScrollableAncestors(triggerElement) {
    var scrollableAncestors = [];

    if (triggerElement) {
      var parent = triggerElement.parentNode;

      if (parent !== null) {
        var nextScrollable = (0, _calculatePosition.getScrollParent)(parent);

        while (nextScrollable && nextScrollable.tagName.toUpperCase() !== 'BODY' && nextScrollable.tagName.toUpperCase() !== 'HTML') {
          scrollableAncestors.push(nextScrollable);
          var nextParent = nextScrollable.parentNode;

          if (nextParent === null) {
            nextScrollable = undefined;
          } else {
            nextScrollable = (0, _calculatePosition.getScrollParent)(nextParent);
          }
        }
      }
    }

    return scrollableAncestors;
  }

  function closestContent(el) {
    while (el && (!el.classList || !el.classList.contains('ember-basic-dropdown-content'))) {
      if (el.parentElement === null) return null;
      el = el.parentElement;
    }

    return el;
  }

  function waitForAnimations(element, callback) {
    window.requestAnimationFrame(function () {
      var computedStyle = window.getComputedStyle(element);

      if (computedStyle.animationName !== 'none' && computedStyle.animationPlayState === 'running') {
        var eventCallback = function () {
          element.removeEventListener('animationend', eventCallback);
          callback();
        };

        element.addEventListener('animationend', eventCallback);
      } else {
        callback();
      }
    });
  }
  /**
   * Evaluates if the given element is in a dropdown or any of its parent dropdowns.
   *
   * @param {HTMLElement} el
   * @param {String} dropdownId
   */


  function dropdownIsValidParent(el, dropdownId) {
    var closestDropdown = closestContent(el);

    if (closestDropdown === null) {
      return false;
    } else {
      var closestAttrs = closestDropdown.attributes;
      var trigger = document.querySelector("[aria-owns=".concat(closestAttrs.id.value, "]"));
      if (trigger === null) return false;
      var parentDropdown = closestContent(trigger);
      if (parentDropdown === null) return false;
      var parentAttrs = parentDropdown.attributes;
      return parentDropdown && parentAttrs.id.value === dropdownId || dropdownIsValidParent(parentDropdown, dropdownId);
    }
  }
});