define("@zestia/ember-select-box/utils/component/value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.receiveValue = receiveValue;
  _exports.resolveValue = resolveValue;
  _exports.startedResolvingValue = startedResolvingValue;
  _exports.finishedResolvingValue = finishedResolvingValue;

  function receiveValue(component) {
    resolveValue(component, component.args.value);
  }

  function resolveValue(component, value, postProcess) {
    var valueID = ++component.valueID;
    startedResolvingValue(component, value);
    return Ember.RSVP.resolve(value).then(result => finishedResolvingValue(component, valueID, false, result, postProcess)).catch(error => finishedResolvingValue(component, valueID, true, error, postProcess));
  }

  function startedResolvingValue(component, value) {
    component.previousValue = component.value;
    component.value = value;
    component.isPending = true;
    component.isRejected = false;
    component.isFulfilled = false;
    component.isSettled = false;
  }

  function finishedResolvingValue(component, valueID, failed, result, postProcess) {
    if (valueID < component.valueID) {
      return;
    }

    var value = result;

    if (typeof postProcess === 'function') {
      value = postProcess(component, value);
    }

    component.value = value;
    component.isPending = false;
    component.isRejected = failed;
    component.isFulfilled = !failed;
    component.isSettled = true;
  }
});