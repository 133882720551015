define("ember-simple-auth/initializers/setup-session-restoration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setupSessionRestoration;

  function setupSessionRestoration(registry) {
    var ApplicationRoute = registry.resolveRegistration ? registry.resolveRegistration('route:application') : registry.resolve('route:application');
    ApplicationRoute.reopen({
      init() {
        this._super(...arguments);

        var originalBeforeModel = this.beforeModel;

        this.beforeModel = function () {
          var session = Ember.getOwner(this).lookup('session:main');
          return session.restore().then(() => originalBeforeModel.apply(this, arguments), () => originalBeforeModel.apply(this, arguments));
        };
      }

    });
  }
});