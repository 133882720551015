define("@zestia/ember-select-box/utils/select-box/search", ["exports", "@zestia/ember-select-box/utils/component/invoke-action"], function (_exports, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.maybeSearch = maybeSearch;
  _exports.cancelSearch = cancelSearch;
  _exports.search = search;

  function maybeSearch(selectBox, query) {
    if (isSearchable(selectBox)) {
      debouncedSearchAttempt(selectBox, query);
    }
  }

  function cancelSearch(selectBox) {
    ++selectBox.searchID;
    searchFinished(selectBox);
  }

  function search(selectBox, query) {
    var delay = selectBox.searchSlowTime;
    selectBox.isSearching = true;
    var searchID = ++selectBox.searchID;
    setTimeout(() => checkSlowSearch(selectBox), delay);
    var action = (0, _invokeAction.default)(selectBox, 'onSearch', query, selectBox.api);
    return Ember.RSVP.resolve(action).then(result => {
      searchCompleted(selectBox, searchID, query, result);
      return result;
    }).catch(error => {
      searchFailed(selectBox, query, error);
      return error;
    }).finally(() => {
      searchFinished(selectBox);
    });
  }

  function debouncedSearchAttempt(selectBox, query) {
    var delay = selectBox.searchDelayTime;
    var immediate = !delay;
    Ember.run.debounce(attemptSearch, selectBox, query, delay, immediate);
  }

  function attemptSearch(selectBox, query) {
    query = "".concat(query).trim();

    if (!queryOK(selectBox, query)) {
      return;
    }

    search(selectBox, query);
  }

  function isSearchable(selectBox) {
    return typeof selectBox.args.onSearch === 'function';
  }

  function queryOK(selectBox, query) {
    return query.length >= selectBox.searchMinChars;
  }

  function checkSlowSearch(selectBox) {
    selectBox.isSlowSearch = selectBox.isSearching;
  }

  function searchCompleted(selectBox, searchID, query, result) {
    if (searchID < selectBox.searchID) {
      return;
    }

    (0, _invokeAction.default)(selectBox, 'onSearched', result, query, selectBox.api);
  }

  function searchFailed(selectBox, query, error) {
    (0, _invokeAction.default)(selectBox, 'onSearchError', error, query, selectBox.api);
  }

  function searchFinished(selectBox) {
    selectBox.isSearching = false;
    selectBox.isSlowSearch = false;
  }
});