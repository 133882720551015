define("@zestia/ember-select-box/utils/select-box/selected-option/deactivate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deactivateSelectedOptions = deactivateSelectedOptions;

  function deactivateSelectedOptions(selectBox) {
    selectBox.activeSelectedOptionIndex = -1;
  }
});