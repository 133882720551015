define("@zestia/ember-select-box/utils/select-box/option/activate", ["exports", "@zestia/ember-select-box/utils/component/invoke-action", "@zestia/ember-select-box/utils/component/filter", "@zestia/ember-select-box/utils/component/scroll-into-view"], function (_exports, _invokeAction, _filter, _scrollIntoView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._activateOption = _activateOption;
  _exports.activateOption = activateOption;
  _exports.activateOptionAtIndex = activateOptionAtIndex;
  _exports.activateNextOption = activateNextOption;
  _exports.activatePreviousOption = activatePreviousOption;
  _exports.activateOptionForValue = activateOptionForValue;
  _exports.activateOptionForKeyCode = activateOptionForKeyCode;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var {
    fromCharCode
  } = String;

  function _activateOption(option) {
    (0, _invokeAction.default)(option, '_onActivate', option);
  }

  function activatedOption(option) {
    (0, _invokeAction.default)(option, 'onActivate', option.value, option.args.selectBox.api);
  }

  function activateOption(selectBox, option, config) {
    activateOptionAtIndex(selectBox, option.index, config);
  }

  function setActiveOptionIndex(selectBox, index) {
    var under = index < 0;
    var over = index > selectBox.options.length - 1;

    if (under || over) {
      return;
    }

    selectBox.activeOptionIndex = index;
  }

  function activateOptionAtIndex(selectBox, index, config) {
    setActiveOptionIndex(selectBox, index);
    var option = selectBox.activeOption;

    if (!option) {
      return;
    }

    (0, _scrollIntoView.maybeScrollIntoView)(option, config);
    activatedOption(option);
  }

  function activateNextOption(selectBox, config) {
    activateOptionAtIndex(selectBox, selectBox.activeOptionIndex + 1, _objectSpread({
      scrollIntoView: true
    }, config));
  }

  function activatePreviousOption(selectBox, config) {
    activateOptionAtIndex(selectBox, selectBox.activeOptionIndex - 1, _objectSpread({
      scrollIntoView: true
    }, config));
  }

  function activateOptionForValue(selectBox, value, config) {
    var option = selectBox.options.find(option => option.value === value);

    if (!option) {
      return;
    }

    activateOption(selectBox, option, _objectSpread({
      scrollIntoView: true
    }, config));
  }

  function activateOptionForKeyCode(selectBox, keyCode, config) {
    var char = fromCharCode(keyCode);

    if (!char) {
      return;
    }

    var option = optionForChar(selectBox, char);

    if (!option) {
      return;
    }

    activateOption(selectBox, option, _objectSpread({
      scrollIntoView: true
    }, config));
  }

  function optionForChar(selectBox, char) {
    var prev = selectBox.optionCharState || {
      chars: '',
      ms: 0,
      index: 0
    };
    var prevChar = prev.chars.substring(prev.chars.length - 1);
    var ms = Date.now();
    var duration = ms - prev.ms;
    var repeatedChar = char === prevChar;
    var reset = duration > 1000;
    var chars = reset ? char : "".concat(prev.chars).concat(char);
    var options = (0, _filter.filterComponentsByTextContent)(selectBox.options, chars);
    var index = 0;
    var option = null;

    if (repeatedChar) {
      index = prev.index + 1;
      options = (0, _filter.filterComponentsByTextContent)(selectBox.options, prevChar);
      option = options[index];
    }

    if (!option) {
      index = 0;
      option = options[index];
    }

    selectBox.optionCharState = {
      chars,
      ms,
      index
    };
    return option;
  }
});