define("@zestia/ember-select-box/utils/shared/element", ["exports", "@zestia/ember-select-box/utils/component/invoke-action"], function (_exports, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.insertElement = insertElement;

  function insertElement(selectBox) {
    insertedElement(selectBox);
  }

  function insertedElement(selectBox) {
    (0, _invokeAction.default)(selectBox, 'onInsertElement', selectBox.api);
  }
});