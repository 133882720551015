define("@zestia/ember-select-box/components/native-select-box/group/index", ["exports", "@glimmer/component", "@zestia/ember-select-box/utils/shared/class-name"], function (_exports, _component, _className) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <optgroup class={{this.className}} ...attributes>
    {{yield}}
  </optgroup>
  */
  {"id":"QQC52fLf","block":"{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"optgroup\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"@zestia/ember-select-box/components/native-select-box/group/index.hbs"}});

  class NativeSelectBoxGroup extends _component.default {
    get className() {
      return (0, _className.buildClassName)(this.args.selectBox, 'group');
    }

  }

  _exports.default = NativeSelectBoxGroup;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NativeSelectBoxGroup);
});