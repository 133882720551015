define("ember-composable-helpers/helpers/union", ["exports", "ember-composable-helpers/utils/as-array"], function (_exports, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.union = union;
  _exports.default = void 0;

  function union([...arrays]) {
    var items = [].concat(...arrays);
    return items.filter((value, index, array) => (0, _asArray.default)(array).indexOf(value) === index);
  }

  var _default = Ember.Helper.helper(union);

  _exports.default = _default;
});