define("ember-in-viewport/modifiers/in-viewport", ["exports", "ember-modifier", "fast-deep-equal"], function (_exports, _emberModifier, _fastDeepEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var WATCHED_ELEMENTS = false
  /* DEBUG */
  ? new WeakSet() : undefined;
  var InViewportModifier = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class InViewportModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "inViewport", _descriptor, this);

      _defineProperty(this, "name", 'in-viewport');

      _defineProperty(this, "lastOptions", void 0);
    }

    get options() {
      // eslint-disable-next-line no-unused-vars
      var _this$args$named = this.args.named,
          {
        onEnter,
        onExit
      } = _this$args$named,
          options = _objectWithoutProperties(_this$args$named, ["onEnter", "onExit"]);

      return options;
    }

    get hasStaleOptions() {
      return !(0, _fastDeepEqual.default)(this.options, this.lastOptions);
    }

    validateArguments() {
      (false && !(this.args.positional.length === 0) && Ember.assert("'{{in-viewport}}' does not accept positional parameters. Specify listeners via 'onEnter' / 'onExit'.", this.args.positional.length === 0));
      (false && !(typeof this.args.named.onEnter === 'function' || typeof this.args.named.onExit === 'function') && Ember.assert("'{{in-viewport}}' either expects 'onEnter', 'onExit' or both to be present.", typeof this.args.named.onEnter === 'function' || typeof this.args.named.onExit === 'function'));
    }

    onEnter() {
      if (this.args.named.onEnter) {
        this.args.named.onEnter.call(null, this.element);
      }
    }

    onExit() {
      if (this.args.named.onExit) {
        this.args.named.onExit.call(null, this.element);
      }
    }

    setupWatcher() {
      (false && !(!WATCHED_ELEMENTS.has(this.element)) && Ember.assert("'".concat(this.element, "' is already being watched. Make sure that '{{in-viewport}}' is only used once on this element and that you are not calling 'inViewport.watchElement(element)' in other places."), !WATCHED_ELEMENTS.has(this.element)));
      if (false
      /* DEBUG */
      ) WATCHED_ELEMENTS.add(this.element);
      this.inViewport.watchElement(this.element, this.options, this.onEnter, this.onExit);
      this.lastOptions = this.options;
    }

    destroyWatcher() {
      if (false
      /* DEBUG */
      ) WATCHED_ELEMENTS.delete(this.element);
      this.inViewport.stopWatching(this.element);
    }

    didInstall() {
      this.setupWatcher();
    }

    didUpdateArguments() {
      if (this.hasStaleOptions) {
        this.destroyWatcher();
        this.setupWatcher();
      }
    }

    didReceiveArguments() {
      this.validateArguments();
    }

    willRemove() {
      this.destroyWatcher();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "inViewport", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onEnter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onExit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onExit"), _class.prototype)), _class));
  _exports.default = InViewportModifier;
});