define("ember-credit-cards/components/input-credit-card-number", ["exports", "ember-credit-cards/utils/has-text-selected", "ember-credit-cards/utils/formatters", "ember-credit-cards/utils/cards", "ember-credit-cards/utils/is-digit-keypress"], function (_exports, _hasTextSelected, _formatters, _cards, _isDigitKeypress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var cardFromNumber = _cards.default.fromNumber;

  function inputValid(value) {
    value = (value + '').replace(/\D/g, '');
    var card = cardFromNumber(value);

    if (card) {
      return value.length <= card.length[card.length.length - 1];
    } else {
      return value.length <= 16;
    }
  }

  var _default = Ember.TextField.extend({
    classNames: ['input-credit-card-number'],
    placeholder: '•••• •••• •••• ••••',
    autocomplete: 'cc-number',
    type: 'tel',
    keyPress: function (e) {
      if (!(0, _isDigitKeypress.default)(e)) {
        return false;
      }

      if ((0, _hasTextSelected.default)(this.element)) {
        return true;
      }

      var digit = String.fromCharCode(e.which);
      var value = this.element.value + digit;
      return inputValid(value);
    },
    value: Ember.computed('number', {
      get() {
        return _formatters.default.formatNumber(this.get('number'));
      },

      set(key, value) {
        this.set('number', value);
        return _formatters.default.formatNumber(value);
      }

    })
  });

  _exports.default = _default;
});