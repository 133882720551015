define("@zestia/ember-select-box/components/native-select-box/option/index", ["exports", "@glimmer/component", "@zestia/ember-select-box/utils/component/lifecycle", "@zestia/ember-select-box/utils/registration/element", "@zestia/ember-select-box/utils/shared/api", "@zestia/ember-select-box/utils/component/value", "@zestia/ember-select-box/utils/shared/selected", "@zestia/ember-select-box/utils/shared/class-name"], function (_exports, _component, _lifecycle, _element, _api, _value, _selected, _className) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <option
    class={{this.className}}
    selected={{this.isSelected}}
    value={{this.value}}
    ...attributes
    {{did-insert this.handleInsertElement}}
    {{did-update this.handleUpdateValue @value}}
    {{will-destroy this.handleDestroyElement}}
  >
    {{~yield this.api~}}
  </option>
  */
  {"id":"e4KDGNFE","block":"{\"symbols\":[\"&attrs\",\"@value\",\"&default\"],\"statements\":[[11,\"option\"],[16,0,[32,0,[\"className\"]]],[16,\"selected\",[32,0,[\"isSelected\"]]],[16,2,[32,0,[\"value\"]]],[17,1],[4,[38,0],[[32,0,[\"handleInsertElement\"]]],null],[4,[38,1],[[32,0,[\"handleUpdateValue\"]],[32,2]],null],[4,[38,2],[[32,0,[\"handleDestroyElement\"]]],null],[12],[18,3,[[32,0,[\"api\"]]]],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"will-destroy\"]}","meta":{"moduleName":"@zestia/ember-select-box/components/native-select-box/option/index.hbs"}});

  var NativeSelectBoxOption = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class NativeSelectBoxOption extends _component.default {
    get api() {
      return (0, _api.default)(this, ['element', 'index', 'isFulfilled', 'isPending', 'isRejected', 'isSelected', 'isSettled', 'value']);
    }

    get className() {
      return (0, _className.buildClassName)(this.args.selectBox, 'option');
    }

    get index() {
      return this.args.selectBox ? this.args.selectBox.options.indexOf(this) : -1;
    }

    get isSelected() {
      return (0, _selected.default)(this);
    }

    constructor() {
      super(...arguments);

      _defineProperty(this, "_api", {});

      _defineProperty(this, "previousValue", null);

      _defineProperty(this, "valueID", 0);

      _initializerDefineProperty(this, "element", _descriptor, this);

      _initializerDefineProperty(this, "isFulfilled", _descriptor2, this);

      _initializerDefineProperty(this, "isPending", _descriptor3, this);

      _initializerDefineProperty(this, "isRejected", _descriptor4, this);

      _initializerDefineProperty(this, "isSettled", _descriptor5, this);

      _initializerDefineProperty(this, "value", _descriptor6, this);

      (0, _value.receiveValue)(this);
    }

    handleInsertElement(element) {
      (0, _element.registerElement)(this, element);
      (0, _lifecycle._insertComponent)(this);
    }

    handleDestroyElement() {
      (0, _element.deregisterElement)(this);
      (0, _lifecycle._destroyComponent)(this);
    }

    handleUpdateValue() {
      (0, _value.receiveValue)(this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "element", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isFulfilled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isPending", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isRejected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isSettled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleUpdateValue", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "handleUpdateValue"), _class.prototype)), _class));
  _exports.default = NativeSelectBoxOption;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NativeSelectBoxOption);
});