define("@zestia/ember-select-box/utils/component/lifecycle", ["exports", "@zestia/ember-select-box/utils/component/invoke-action"], function (_exports, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._insertComponent = _insertComponent;
  _exports._destroyComponent = _destroyComponent;

  function _insertComponent(component) {
    (0, _invokeAction.default)(component, '_onInsert', component);
  }

  function _destroyComponent(component) {
    (0, _invokeAction.default)(component, '_onDestroy', component);
  }
});