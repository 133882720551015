define("ember-leaflet/templates/div-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D1wPJjXY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[32,0,[\"isFastBoot\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"shouldRender\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,0,[\"destinationElement\"]]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"closePopup\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,1,[[30,[36,0],[[32,0,[\"closePopup\"]]],null]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\",\"-in-el-null\",\"in-element\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-leaflet/templates/div-overlay.hbs"
    }
  });

  _exports.default = _default;
});