define("ember-ajax/mixins/ajax-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * The AJAX service to send requests through
     *
     * @property {AjaxService} ajaxService
     * @public
     */
    ajaxService: Ember.inject.service('ajax'),

    /**
     * @property {string} host
     * @public
     */
    host: Ember.computed.alias('ajaxService.host'),

    /**
     * @property {string} namespace
     * @public
     */
    namespace: Ember.computed.alias('ajaxService.namespace'),

    /**
     * @property {object} headers
     * @public
     */
    headers: Ember.computed.alias('ajaxService.headers'),

    ajax(url, _method, _options) {
      // @ts-ignore
      var augmentedOptions = this.ajaxOptions(...arguments);
      return Ember.get(this, 'ajaxService').request(url, augmentedOptions);
    }

  });

  _exports.default = _default;
});