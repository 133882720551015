define("ember-drag-drop/services/drag-coordinator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function indexOf(items, a) {
    return items.findIndex(function (element) {
      return Ember.isEqual(element, a);
    });
  }

  function swapInPlace(items, a, b) {
    var aPos = indexOf(items, a);
    var bPos = indexOf(items, b);
    items.replace(aPos, 1, [b]);
    items.replace(bPos, 1, [a]);
  }

  function shiftInPlace(items, a, b) {
    var aPos = indexOf(items, a);
    var bPos = indexOf(items, b);
    items.removeAt(aPos);
    items.insertAt(bPos, a);
  }

  var _default = Ember.Service.extend({
    sortComponentController: null,
    currentDragObject: null,
    currentDragEvent: null,
    currentDragItem: null,
    currentOffsetItem: null,
    isMoving: false,
    lastEvent: null,

    init() {
      this._super(...arguments); // Use object for sortComponents so that we can scope per sortingScope


      this.set('sortComponents', {});
    },

    arrayList: Ember.computed.alias('sortComponentController.sortableObjectList'),
    enableSort: Ember.computed.alias('sortComponentController.enableSort'),
    useSwap: Ember.computed.alias('sortComponentController.useSwap'),
    inPlace: Ember.computed.alias('sortComponentController.inPlace'),

    pushSortComponent(component) {
      var sortingScope = component.get('sortingScope');

      if (!this.get('sortComponents')[sortingScope]) {
        this.get('sortComponents')[sortingScope] = Ember.A();
      }

      this.get('sortComponents')[sortingScope].pushObject(component);
    },

    removeSortComponent(component) {
      var sortingScope = component.get('sortingScope');
      this.get('sortComponents')[sortingScope].removeObject(component);
    },

    dragStarted(object, event, emberObject) {
      this.set('currentDragObject', object);
      this.set('currentDragEvent', event);
      this.set('currentDragItem', emberObject);
      event.dataTransfer.effectAllowed = 'move';
    },

    dragEnded() {
      this.set('currentDragObject', null);
      this.set('currentDragEvent', null);
      this.set('currentDragItem', null);
      this.set('currentOffsetItem', null);
    },

    draggingOver(event, emberObject) {
      var currentOffsetItem = this.get('currentOffsetItem');
      var pos = this.relativeClientPosition(emberObject.element, event);
      var hasSameSortingScope = this.get('currentDragItem.sortingScope') === emberObject.get('sortingScope');
      var moveDirections = [];

      if (!this.get('lastEvent')) {
        this.set('lastEvent', event);
      }

      if (event.clientY < this.get('lastEvent').clientY) {
        moveDirections.push('up');
      }

      if (event.clientY > this.get('lastEvent').clientY) {
        moveDirections.push('down');
      }

      if (event.clientX < this.get('lastEvent').clientX) {
        moveDirections.push('left');
      }

      if (event.clientX > this.get('lastEvent').clientX) {
        moveDirections.push('right');
      }

      this.set('lastEvent', event);

      if (!this.get('isMoving') && this.get('currentDragEvent')) {
        if (event.target !== this.get('currentDragEvent').target && hasSameSortingScope) {
          //if not dragging over self
          if (currentOffsetItem !== emberObject) {
            if (pos.py < 0.67 && moveDirections.indexOf('up') >= 0 || pos.py > 0.33 && moveDirections.indexOf('down') >= 0 || pos.px < 0.67 && moveDirections.indexOf('left') >= 0 || pos.px > 0.33 && moveDirections.indexOf('right') >= 0) {
              this.moveElements(emberObject);
              this.set('currentOffsetItem', emberObject);
            }
          }
        } else {
          //reset because the node moved under the mouse with a move
          this.set('currentOffsetItem', null);
        }
      }
    },

    moveObjectPositions(a, b, sortComponents) {
      var aSortable = sortComponents.find(component => {
        return component.get('sortableObjectList').find(sortable => {
          return Ember.isEqual(sortable, a);
        });
      });
      var bSortable = sortComponents.find(component => {
        return component.get('sortableObjectList').find(sortable => {
          return Ember.isEqual(sortable, b);
        });
      });
      var swap = aSortable === bSortable;

      if (swap) {
        var list = aSortable.get('sortableObjectList');

        if (!this.get('inPlace')) {
          list = Ember.A(list.toArray());
        }

        if (this.get('useSwap')) {
          swapInPlace(list, a, b);
        } else {
          shiftInPlace(list, a, b);
        }

        if (!this.get('inPlace')) {
          aSortable.set('sortableObjectList', list);
        }
      } else {
        // Move if items are in different sortable-objects component
        var aList = aSortable.get('sortableObjectList');
        var bList = bSortable.get('sortableObjectList'); // Remove from aList and insert into bList

        aList.removeObject(a);
        bList.insertAt(indexOf(bList, b), a);
      }
    },

    moveElements(overElement) {
      var isEnabled = Object.keys(this.get('sortComponents')).length;
      var draggingItem = this.get('currentDragItem');
      var sortComponents = this.get('sortComponents')[draggingItem.get('sortingScope')];

      if (!isEnabled) {
        return;
      }

      this.moveObjectPositions(draggingItem.get('content'), overElement.get('content'), sortComponents);
      sortComponents.forEach(component => {
        component.rerender();
      });
    },

    relativeClientPosition(el, event) {
      var rect = el.getBoundingClientRect();
      var x = event.clientX - rect.left;
      var y = event.clientY - rect.top;
      return {
        x: x,
        y: y,
        px: x / rect.width,
        py: y / rect.height
      };
    }

  });

  _exports.default = _default;
});