define("ember-intl/macros/t", ["exports", "ember-intl/-private/utils/empty-object", "ember-intl/macros/intl"], function (_exports, _emptyObject, _intl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.raw = raw;
  _exports.default = createTranslatedComputedProperty;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function partitionDynamicValuesAndStaticValues(options) {
    var dynamicValues = new _emptyObject.default();
    var staticValues = new _emptyObject.default();
    Object.keys(options).forEach(key => {
      var value = options[key];

      if (value instanceof Raw) {
        staticValues[key] = value.valueOf();
      } else {
        dynamicValues[key] = value;
      }
    });
    return [dynamicValues, staticValues];
  }

  function mapPropertiesByHash(object, hash) {
    var result = new _emptyObject.default();
    Object.keys(hash).forEach(key => {
      result[key] = Ember.get(object, hash[key]);
    });
    return result;
  }
  /**
   * This class is used to box primitive types and mark them as raw literals that
   * should be used as is by the translation macro.
   *
   * This class is internal. Instead of using this class directly, use the `raw`
   * utility function, that creates an instance of this class.
   */


  class Raw {
    constructor(value) {
      _defineProperty(this, "_value", void 0);

      this._value = value;
    }

    valueOf() {
      return this._value;
    }

    toString() {
      return String(this._value);
    }

  }
  /**
   * Use this utility function to mark a value as a raw literal.
   *
   * @param {*} value The value to mark as a raw literal.
   * @return The same value, but boxed in the `Raw` class so that the consuming
   *  macro understands that this value should be used as is.
   */


  function raw(value) {
    return new Raw(value);
  }

  function createTranslatedComputedProperty(translationKey, options) {
    var hash = options || new _emptyObject.default();
    var [dynamicValues, staticValues] = partitionDynamicValuesAndStaticValues(hash);
    var dependentKeys = Object.values(dynamicValues); // @ts-expect-error TS2556: Can't deal with leading rest arguments

    return (0, _intl.default)(...dependentKeys, (intl, propertyKey, ctx) => intl.t(translationKey, _objectSpread(_objectSpread({}, staticValues), mapPropertiesByHash(ctx, dynamicValues))));
  }
});