define("ember-leaflet/components/tooltip-layer", ["exports", "ember-leaflet/components/div-overlay-layer"], function (_exports, _divOverlayLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _divOverlayLayer.default.extend({
    leafletOptions: Object.freeze(['direction', 'permanent', 'sticky', 'interactive', 'opacity']),
    // if this tooltip is permanent, we need to render the content immediately
    shouldRender: Ember.computed.reads('permanent'),

    createLayer() {
      return this.L.tooltip(this.get('options')).setContent(this.get('destinationElement'));
    },

    didCreateLayer() {
      this._addPopupListeners();
    },

    addToContainer() {
      this.get('parentComponent')._layer.bindTooltip(this._layer);
    },

    removeFromContainer() {
      this.get('parentComponent')._layer.unbindTooltip();
    },

    _onLayerRemove({
      layer
    }) {
      if (layer === this._layer) {
        this._removePopupListeners();

        this.set('shouldRender', false);
      }
    },

    _addPopupListeners() {
      // we need to hijack the `onAdd` method because we need to
      // render the template *before* the popup is opened.
      // This way, the popup will set its dimensions according to the rendered DOM.
      var oldOnAdd = this._layer.onAdd;

      this._layer.onAdd = map => {
        // we need to user `layerremove` event becase it's the only one that fires
        // *after* the popup was completely removed from the map
        map.addEventListener('layerremove', this._onLayerRemove, this); // trigger _initLayout manually, otherwise Tooltip doesn't have the container set
        // to calculate initial position

        if (!this._layer._container) {
          this._layer._initLayout();
        } // this will make in-element render to the document fragment


        this.set('shouldRender', true); // ember-wormhole will render on the afterRender queue, so we need to render after that

        Ember.run.next(() => {
          if (this.get('shouldRender')) {
            oldOnAdd.call(this._layer, map);
          }
        });
      };
    },

    _removePopupListeners() {
      var parentComponent = this.get('parentComponent');

      parentComponent._layer._map.removeEventListener('layerremove', this._onLayerRemove, this);
    }

  });

  _exports.default = _default;
});