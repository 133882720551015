define("@zestia/ember-select-box/utils/component/filter", ["exports", "@zestia/ember-select-box/utils/general/escape-regexp", "@zestia/ember-select-box/utils/general/collapse-whitespace"], function (_exports, _escapeRegexp, _collapseWhitespace) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterComponentsByTextContent = filterComponentsByTextContent;

  function filterComponentsByTextContent(components, query) {
    query = (0, _escapeRegexp.default)(query);
    var pattern = new RegExp("^".concat(query), 'i');
    return components.filter(option => pattern.test((0, _collapseWhitespace.default)(option.element.textContent)));
  }
});