define("@zestia/ember-select-box/utils/shared/id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = id;

  function id(selectBox) {
    return Ember.guidFor(selectBox).replace('ember', 'select-box-el-');
  }
});