define("ember-credit-cards/utils/is-whitelist-keypress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isDigitKeypress;
  var validKeyCodes = Ember.A([9, 8, 46, 27, 13]);

  function isDigitKeypress(e) {
    var keyCode = e.keyCode || e.which;
    return validKeyCodes.includes(keyCode);
  }
});