define("@zestia/ember-select-box/utils/registration/selected-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerSelectedOptionsContainer = registerSelectedOptionsContainer;
  _exports.deregisterSelectedOptionsContainer = deregisterSelectedOptionsContainer;

  function registerSelectedOptionsContainer(selectBox, selectedOptionsContainer) {
    (false && !(!selectBox.optionsContainer) && Ember.assert('A select box can only have 1 selected options container', !selectBox.optionsContainer));
    selectBox.selectedOptionsContainer = selectedOptionsContainer;
  }

  function deregisterSelectedOptionsContainer(selectBox, selectedOptionsContainer) {
    selectBox.selectedOptionsContainer = null;
  }
});