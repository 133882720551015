define("@zestia/ember-select-box/utils/shared/api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildAPI;
  var {
    seal
  } = Object;

  function buildAPI(component, members) {
    if (component.isDestroying) {
      return;
    }

    members.forEach(member => {
      component._api[member] = component[member];
    });
    return seal(component._api);
  }
});