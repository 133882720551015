define("ember-ref-modifier/modifiers/ref", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function hasValidTarget(target) {
    return typeof target === 'object' && target !== null && !Array.isArray(target);
  }

  function hasValidProperty(prop) {
    return typeof prop === 'string';
  }

  function getParams([target, propName]) {
    if (typeof target === 'function') {
      return {
        cb: target
      };
    }

    (false && !(typeof target !== 'string') && Ember.assert("String ".concat(target, " used as context for ref modifier. Should be {{ref context \"").concat(target, "\"}}. You passed {{ref \"").concat(target, "\" context}}"), typeof target !== 'string'));
    return {
      target,
      propName
    };
  }

  var _default = Ember._setModifierManager(() => ({
    _runCache: new Set(),
    capabilities: Ember._modifierManagerCapabilities ? Ember._modifierManagerCapabilities('3.13') : undefined,

    createModifier() {
      return {
        element: undefined,
        propName: undefined,
        cb: undefined,
        target: undefined
      };
    },

    installModifier(state, element, {
      positional
    }) {
      var {
        propName,
        target,
        cb
      } = getParams(positional);

      if (cb) {
        state.cb = cb;

        this._runInContext(cb, element);

        return;
      }

      if (hasValidProperty(propName) && hasValidTarget(target)) {
        this._setInContext(target, propName, element);

        state.propName = propName;
        state.target = target;
      }

      state.element = element;
    },

    updateModifier(state, {
      positional
    }) {
      var {
        propName,
        target,
        cb
      } = getParams(positional);

      if (cb) {
        state.cb = cb;

        this._runInContext(cb, state.element);

        return;
      }

      if (hasValidProperty(propName) && hasValidTarget(target)) {
        if (hasValidProperty(state.propName) && hasValidTarget(state.target)) {
          if (Ember.get(target, propName) !== Ember.get(state.target, state.propName)) {
            this._setInContext(state.target, state.propName, null);
          }
        }

        this._setInContext(target, propName, state.element);

        state.propName = propName;
        state.target = target;
      }
    },

    _setInContext(target, propName, value) {
      var cancelToken = Ember.run.next(this, '_setValues', target, propName, value);

      this._runCache.add(cancelToken);
    },

    _runInContext(cb, value) {
      var cancelToken = Ember.run.next(this, '_runCb', cb, value);

      this._runCache.add(cancelToken);
    },

    _runCb(cb, value) {
      cb(value);
    },

    _setValues(target, propName, value) {
      if (target.isDestroyed || target.isDestroying) {
        return;
      }

      Ember.set(target, propName, value);
    },

    destroyModifier({
      target,
      propName,
      cb
    }) {
      this._runCache.forEach(cancelToken => Ember.run.cancel(cancelToken));

      if (cb) {
        return;
      }

      if (hasValidProperty(propName) && hasValidTarget(target)) {
        this._setInContext(target, propName, null);
      }
    }

  }), class RefModifier {});

  _exports.default = _default;
});