define("@zestia/ember-select-box/utils/select-box/document", ["exports", "@zestia/ember-select-box/utils/component/invoke-action"], function (_exports, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addDocumentClickListener = addDocumentClickListener;
  _exports.removeDocumentClickListener = removeDocumentClickListener;

  function addDocumentClickListener(selectBox) {
    selectBox.documentClickHandler = e => clickDocument(selectBox, e);

    document.addEventListener('click', selectBox.documentClickHandler, {
      capture: true
    });
    document.addEventListener('touchstart', selectBox.documentClickHandler, {
      capture: true,
      passive: true
    });
  }

  function removeDocumentClickListener(selectBox) {
    document.removeEventListener('click', selectBox.documentClickHandler, {
      capture: true
    });
    document.removeEventListener('touchstart', selectBox.documentClickHandler, {
      capture: true,
      passive: true
    });
    selectBox.documentClickHandler = null;
  }

  function clickDocument(selectBox, e) {
    detectClickedOutside(selectBox, e);
  }

  function detectClickedOutside(selectBox, e) {
    var el = selectBox.element;
    var clickedSelf = el === e.target;
    var clickedInside = el.contains(e.target);
    var clickedOutside = !clickedSelf && !clickedInside;

    if (clickedOutside) {
      clickOutside(selectBox, e);
    }
  }

  function clickOutside(selectBox, e) {
    clickedOutside(selectBox);
  }

  function clickedOutside(selectBox, e) {
    (0, _invokeAction.default)(selectBox, 'onClickOutside', e, selectBox.api);
  }
});