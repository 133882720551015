define("@zestia/ember-select-box/components/select-box/group/index", ["exports", "@glimmer/component", "@zestia/ember-select-box/utils/shared/class-name"], function (_exports, _component, _className) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div data-component="group" class={{this.className}} ...attributes>
    <div class={{this.groupLabelClassName}}>
      {{@label}}
    </div>
    <div class={{this.groupOptionsClassName}}>
      {{yield}}
    </div>
  </div>
  */
  {"id":"m/u0Aoyo","block":"{\"symbols\":[\"&attrs\",\"@label\",\"&default\"],\"statements\":[[11,\"div\"],[24,\"data-component\",\"group\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[32,0,[\"groupLabelClassName\"]]],[12],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[32,0,[\"groupOptionsClassName\"]]],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"@zestia/ember-select-box/components/select-box/group/index.hbs"}});

  class SelectBoxGroup extends _component.default {
    get className() {
      return (0, _className.buildClassName)(this.args.selectBox, 'group');
    }

    get groupLabelClassName() {
      return (0, _className.buildClassName)(this.args.selectBox, 'group-label');
    }

    get groupOptionsClassName() {
      return (0, _className.buildClassName)(this.args.selectBox, 'group-options');
    }

  }

  _exports.default = SelectBoxGroup;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SelectBoxGroup);
});