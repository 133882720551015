define("@zestia/ember-select-box/components/select-box/option/index", ["exports", "@glimmer/component", "@zestia/ember-select-box/utils/select-box/option/activate", "@zestia/ember-select-box/utils/component/lifecycle", "@zestia/ember-select-box/utils/select-box/option/select", "@zestia/ember-select-box/utils/registration/element", "@zestia/ember-select-box/utils/component/value", "@zestia/ember-select-box/utils/shared/selected", "@zestia/ember-select-box/utils/shared/api", "@zestia/ember-select-box/utils/shared/id", "@zestia/ember-select-box/utils/select-box/option/class-name"], function (_exports, _component, _activate, _lifecycle, _select, _element, _value, _selected, _api, _id, _className) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    data-component="option"
    aria-busy={{if this.isPending "true" "false"}}
    aria-current={{if this.isActive "true" "false"}}
    aria-disabled={{if this.isDisabled "true" "false"}}
    aria-selected={{if this.isSelected "true" "false"}}
    class={{this.className}}
    id={{this.id}}
    role="option"
    ...attributes
    {{did-insert this.handleInsertElement}}
    {{did-update this.handleUpdateValue @value}}
    {{will-destroy this.handleDestroyElement}}
    {{on "mouseenter" this.handleMouseEnter}}
    {{on "click" this.handleClick}}
  >
    {{~yield this.api~}}
  </div>
  */
  {"id":"9nxUDf8T","block":"{\"symbols\":[\"&attrs\",\"@value\",\"&default\"],\"statements\":[[11,\"div\"],[24,\"data-component\",\"option\"],[16,\"aria-busy\",[30,[36,0],[[32,0,[\"isPending\"]],\"true\",\"false\"],null]],[16,\"aria-current\",[30,[36,0],[[32,0,[\"isActive\"]],\"true\",\"false\"],null]],[16,\"aria-disabled\",[30,[36,0],[[32,0,[\"isDisabled\"]],\"true\",\"false\"],null]],[16,\"aria-selected\",[30,[36,0],[[32,0,[\"isSelected\"]],\"true\",\"false\"],null]],[16,0,[32,0,[\"className\"]]],[16,1,[32,0,[\"id\"]]],[24,\"role\",\"option\"],[17,1],[4,[38,1],[[32,0,[\"handleInsertElement\"]]],null],[4,[38,2],[[32,0,[\"handleUpdateValue\"]],[32,2]],null],[4,[38,3],[[32,0,[\"handleDestroyElement\"]]],null],[4,[38,4],[\"mouseenter\",[32,0,[\"handleMouseEnter\"]]],null],[4,[38,4],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[18,3,[[32,0,[\"api\"]]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"did-update\",\"will-destroy\",\"on\"]}","meta":{"moduleName":"@zestia/ember-select-box/components/select-box/option/index.hbs"}});

  var SelectBoxOption = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = (_temp = class SelectBoxOption extends _component.default {
    get api() {
      return (0, _api.default)(this, ['element', 'index', 'isActive', 'isDisabled', 'isFulfilled', 'isPending', 'isRejected', 'isSelected', 'isSettled', 'value']);
    }

    get className() {
      return (0, _className.default)(this);
    }

    get id() {
      return (0, _id.default)(this);
    }

    get index() {
      return this.args.selectBox ? this.args.selectBox.options.indexOf(this) : -1;
    }

    get isActive() {
      return this.args.selectBox ? this.index === this.args.selectBox.activeOptionIndex : false;
    }

    get isDisabled() {
      return this.args.disabled;
    }

    get isSelected() {
      return (0, _selected.default)(this);
    }

    constructor() {
      super(...arguments);

      _defineProperty(this, "_api", {});

      _defineProperty(this, "previousValue", null);

      _defineProperty(this, "valueID", 0);

      _initializerDefineProperty(this, "element", _descriptor, this);

      _initializerDefineProperty(this, "isFulfilled", _descriptor2, this);

      _initializerDefineProperty(this, "isPending", _descriptor3, this);

      _initializerDefineProperty(this, "isRejected", _descriptor4, this);

      _initializerDefineProperty(this, "isSettled", _descriptor5, this);

      _initializerDefineProperty(this, "value", _descriptor6, this);

      (0, _value.receiveValue)(this);
    }

    handleInsertElement(element) {
      (0, _element.registerElement)(this, element);
      (0, _lifecycle._insertComponent)(this);
    }

    handleDestroyElement() {
      (0, _element.deregisterElement)(this);
      (0, _lifecycle._destroyComponent)(this);
    }

    handleUpdateValue() {
      (0, _value.receiveValue)(this);
    }

    handleMouseEnter() {
      (0, _activate._activateOption)(this);
    }

    handleClick() {
      (0, _select._selectOption)(this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "element", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isFulfilled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isPending", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isRejected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isSettled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleUpdateValue", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "handleUpdateValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMouseEnter", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleClick", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype)), _class));
  _exports.default = SelectBoxOption;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SelectBoxOption);
});