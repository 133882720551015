define("@zestia/ember-select-box/utils/registration/selected-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerSelectedOption = registerSelectedOption;
  _exports.deregisterSelectedOption = deregisterSelectedOption;

  function registerSelectedOption(selectBox, selectedOption) {
    selectBox.pendingSelectedOptions.addObject(selectedOption);
    scheduleUpdateSelectedOptions(selectBox);
  }

  function deregisterSelectedOption(selectBox, selectedOption) {
    selectBox.pendingSelectedOptions.removeObject(selectedOption);
    scheduleUpdateSelectedOptions(selectBox);
  }

  function scheduleUpdateSelectedOptions(selectBox) {
    Ember.run.scheduleOnce('afterRender', selectBox, updateSelectedOptions, selectBox);
  }

  function updateSelectedOptions(selectBox) {
    if (!selectBox.element) {
      return;
    }

    setSelectedOptions(selectBox, selectBox.pendingSelectedOptions);
  }

  function setSelectedOptions(selectBox, selectedOptions) {
    var elements = [...selectBox.element.querySelectorAll('[data-component="selected-option"]')];

    var sort = (a, b) => elements.indexOf(a.element) - elements.indexOf(b.element);

    selectBox.selectedOptions = selectedOptions.toArray().sort(sort);
  }
});