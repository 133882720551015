define("@zestia/ember-select-box/utils/registration/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerOption = registerOption;
  _exports.deregisterOption = deregisterOption;

  function registerOption(selectBox, option) {
    selectBox.pendingOptions.addObject(option);
    scheduleUpdateOptions(selectBox);
  }

  function deregisterOption(selectBox, option) {
    selectBox.pendingOptions.removeObject(option);
    scheduleUpdateOptions(selectBox);
  }

  function scheduleUpdateOptions(selectBox) {
    Ember.run.scheduleOnce('afterRender', selectBox, updateOptions, selectBox);
  }

  function updateOptions(selectBox) {
    if (!selectBox.element) {
      return;
    }

    setOptions(selectBox, selectBox.pendingOptions);
  }

  function setOptions(selectBox, options) {
    var elements = [...selectBox.element.querySelectorAll('[data-component="option"]')];

    var sort = (a, b) => elements.indexOf(a.element) - elements.indexOf(b.element);

    selectBox.options = options.toArray().sort(sort);
  }
});