define("@zestia/ember-select-box/components/select-box/input/index", ["exports", "@glimmer/component", "@zestia/ember-select-box/utils/component/lifecycle", "@zestia/ember-select-box/utils/registration/element", "@zestia/ember-select-box/utils/select-box/input/keyboard", "@zestia/ember-select-box/utils/shared/class-name"], function (_exports, _component, _lifecycle, _element, _keyboard, _className) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <input
    data-component="input"
    aria-multiline="false"
    autocomplete="off"
    class={{this.className}}
    role="searchbox"
    {{! Issue: https://github.com/emberjs/rfcs/issues/497 }}
    type={{if @type @type "text"}}
    aria-controls={{@aria-controls}}
    disabled={{@disabled}}
    onclick={{@onclick}}
    value={{@value}}
    ...attributes
    {{did-insert this.handleInsertElement}}
    {{will-destroy this.handleDestroyElement}}
    {{on "input" this.handleInput}}
    {{on "keydown" this.handleKeyDown}}
  />
  */
  {"id":"rATx/pf0","block":"{\"symbols\":[\"@aria-controls\",\"@disabled\",\"@onclick\",\"@value\",\"&attrs\",\"@type\"],\"statements\":[[11,\"input\"],[24,\"data-component\",\"input\"],[24,\"aria-multiline\",\"false\"],[24,\"autocomplete\",\"off\"],[16,0,[32,0,[\"className\"]]],[24,\"role\",\"searchbox\"],[16,\"aria-controls\",[32,1]],[16,\"disabled\",[32,2]],[16,\"onclick\",[32,3]],[16,2,[32,4]],[17,5],[16,4,[30,[36,0],[[32,6],[32,6],\"text\"],null]],[4,[38,1],[[32,0,[\"handleInsertElement\"]]],null],[4,[38,2],[[32,0,[\"handleDestroyElement\"]]],null],[4,[38,3],[\"input\",[32,0,[\"handleInput\"]]],null],[4,[38,3],[\"keydown\",[32,0,[\"handleKeyDown\"]]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"will-destroy\",\"on\"]}","meta":{"moduleName":"@zestia/ember-select-box/components/select-box/input/index.hbs"}});

  var SelectBoxInput = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class SelectBoxInput extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "element", _descriptor, this);
    }

    get className() {
      return (0, _className.buildClassName)(this.args.selectBox, 'input');
    }

    handleInsertElement(element) {
      (0, _element.registerElement)(this, element);
      (0, _lifecycle._insertComponent)(this);
    }

    handleDestroyElement() {
      (0, _element.deregisterElement)(this);
      (0, _lifecycle._destroyComponent)(this);
    }

    handleInput(e) {
      (0, _keyboard.input)(this, e);
    }

    handleKeyDown(e) {
      (0, _keyboard.keyDown)(this, e);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "element", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInput", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeyDown", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyDown"), _class.prototype)), _class));
  _exports.default = SelectBoxInput;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SelectBoxInput);
});