define("ember-credit-cards/components/credit-card-form", ["exports", "ember-credit-cards/utils/validations", "ember-credit-cards/utils/cards"], function (_exports, _validations, _cards) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    classNames: ['credit-card-form'],
    classNameBindings: ['isValid'],
    name: null,
    number: null,
    month: null,
    year: null,
    cvc: null,
    zipcode: null,
    zipcodeRequired: false,

    onValidate() {},

    isValid: Ember.computed.and('nameValid', 'numberValid', 'expirationValid', 'cvcValid', 'zipcodeValid'),
    becameValid: Ember.observer('isValid', function () {
      this.get('onValidate')(this.get('isValid'));
    }),
    nameValid: Ember.computed('name', function () {
      var name = this.get('name');

      if (name) {
        return true;
      }

      return false;
    }),
    numberValid: Ember.computed('number', function () {
      var number = this.get('number');
      return _validations.default.validateNumber(number);
    }),
    expirationValid: Ember.computed('month', 'year', function () {
      var month = this.get('month');
      var year = this.get('year');
      return _validations.default.validateExpiration(month, year);
    }),
    cvcValid: Ember.computed('cvc', 'type', function () {
      var cvc = this.get('cvc');
      var type = this.get('type');
      return _validations.default.validateCVC(cvc, type);
    }),
    zipcodeValid: Ember.computed('zipcodeRequired', 'zipcode', function () {
      if (this.get('zipcodeRequired')) {
        var zip = this.get('zipcode');
        return _validations.default.validateZipcode(zip);
      }

      return true;
    }),
    type: Ember.computed('number', function () {
      var number = this.get('number');

      var card = _cards.default.fromNumber(number);

      if (card) {
        return card.type;
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});