define("ember-composable-helpers/helpers/shuffle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shuffle = shuffle;
  _exports.default = void 0;

  function shuffle(array, randomizer) {
    array = array.slice(0);
    var count = array.length;
    var rand, temp;
    randomizer = Ember.typeOf(randomizer) === 'function' && randomizer || Math.random;

    while (count > 1) {
      rand = Math.floor(randomizer() * count--);
      temp = array[count];
      array[count] = array[rand];
      array[rand] = temp;
    }

    return array;
  }

  var _default = Ember.Helper.helper(function ([randomizer, array]) {
    if (array === undefined) {
      array = randomizer;
      randomizer = undefined;
    }

    if (!Ember.isArray(array)) {
      return [array];
    }

    return shuffle(array, randomizer);
  });

  _exports.default = _default;
});