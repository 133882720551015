define("tracked-toolbox/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.localCopy = localCopy;
  _exports.trackedReset = trackedReset;
  _exports.cached = cached;
  _exports.dedupeTracked = dedupeTracked;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Meta = (_dec = Ember._tracked, (_class = (_temp = class Meta {
    constructor() {
      _defineProperty(this, "prevRemote", void 0);

      _defineProperty(this, "peek", void 0);

      _initializerDefineProperty(this, "value", _descriptor, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));

  function getOrCreateMeta(instance, metas, initializer) {
    var meta = metas.get(instance);

    if (meta === undefined) {
      meta = new Meta();
      metas.set(instance, meta);
      meta.value = meta.peek = typeof initializer === 'function' ? initializer.call(instance) : initializer;
    }

    return meta;
  }

  function localCopy(memo, initializer) {
    (false && !(typeof memo === 'string' || typeof memo === 'function') && Ember.assert("@localCopy() must be given a memo path or memo function as its first argument, received `".concat(String(memo), "`"), typeof memo === 'string' || typeof memo === 'function'));
    var metas = new WeakMap();
    return (_prototype, key) => {
      var memoFn = typeof memo === 'function' ? (obj, last) => memo.call(obj, obj, key, last) : obj => Ember.get(obj, memo);
      return {
        get() {
          var meta = getOrCreateMeta(this, metas, initializer);
          var {
            prevRemote
          } = meta;
          var incomingValue = memoFn(this, prevRemote);

          if (prevRemote !== incomingValue) {
            // If the incoming value is not the same as the previous incoming value,
            // update the local value to match the new incoming value, and update
            // the previous incoming value.
            meta.value = meta.prevRemote = incomingValue;
          }

          return meta.value;
        },

        set(value) {
          getOrCreateMeta(this, metas, initializer).value = value;
        }

      };
    };
  }

  function trackedReset(memoOrConfig) {
    (false && !(typeof memoOrConfig === 'string' || typeof memoOrConfig === 'function' || typeof memoOrConfig === 'object' && memoOrConfig !== null && memoOrConfig.memo !== undefined) && Ember.assert("@trackedReset() must be given a memo path, a memo function, or config object with a memo path or function as its first argument, received `".concat(String(memoOrConfig), "`"), typeof memoOrConfig === 'string' || typeof memoOrConfig === 'function' || typeof memoOrConfig === 'object' && memoOrConfig !== null && memoOrConfig.memo !== undefined));
    var metas = new WeakMap();
    return (_prototype, key, desc) => {
      var _desc$initializer;

      var memo, update;
      var initializer = (_desc$initializer = desc.initializer) !== null && _desc$initializer !== void 0 ? _desc$initializer : () => undefined;

      if (typeof memoOrConfig === 'object') {
        var _memoOrConfig$update;

        memo = memoOrConfig.memo;
        update = (_memoOrConfig$update = memoOrConfig.update) !== null && _memoOrConfig$update !== void 0 ? _memoOrConfig$update : initializer;
      } else {
        memo = memoOrConfig;
        update = initializer;
      }

      var memoFn = typeof memo === 'function' ? (obj, last) => memo.call(obj, obj, key, last) : obj => Ember.get(obj, memo);
      return {
        get() {
          var meta = getOrCreateMeta(this, metas, initializer);
          var {
            prevRemote
          } = meta;
          var incomingValue = memoFn(this, prevRemote);

          if (incomingValue !== prevRemote) {
            meta.prevRemote = incomingValue;
            meta.value = meta.peek = update.call(this, this, key, meta.peek);
          }

          return meta.value;
        },

        set(value) {
          getOrCreateMeta(this, metas, initializer).value = value;
        }

      };
    };
  }

  function cached(target, key, value) {
    (false && !(value && value.get) && Ember.assert('@cached can only be used on getters', value && value.get));
    var {
      get,
      set
    } = value;
    var caches = new WeakMap();
    return {
      get() {
        var cache = caches.get(this);

        if (cache === undefined) {
          cache = Ember._createCache(get.bind(this));
          caches.set(this, cache);
        }

        return Ember._cacheGetValue(cache);
      },

      set
    };
  }

  function dedupeTracked(target, key, desc) {
    var {
      initializer
    } = desc;

    var {
      get,
      set
    } = Ember._tracked(target, key, desc);

    var values = new WeakMap();
    return {
      get() {
        if (!values.has(this)) {
          var value = initializer === null || initializer === void 0 ? void 0 : initializer.call(this);
          values.set(this, value);
          set.call(this, value);
        }

        return get.call(this);
      },

      set(value) {
        if (!values.has(this) || value !== values.get(this)) {
          values.set(this, value);
          set.call(this, value);
        }
      }

    };
  }
});