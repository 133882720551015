define("@zestia/ember-select-box/utils/select-box/role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.configureAsCombobox = configureAsCombobox;
  _exports.configureAsListbox = configureAsListbox;

  function configureAsCombobox(selectBox) {
    selectBox.tabIndex = '-1';
    selectBox.role = 'combobox';
  }

  function configureAsListbox(selectBox) {
    selectBox.tabIndex = '0';
    selectBox.role = 'listbox';
  }
});