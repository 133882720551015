define("ember-macro-helpers/flatten-keys", ["exports", "ember-macro-helpers/-computed-store"], function (_exports, _computedStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function flattenKey(key, flattenedKeys) {
    var cp = _computedStore.default.get(key);

    if (cp) {
      return _flattenKeys(cp.dependentKeys, flattenedKeys);
    }

    if (typeof key !== 'string') {
      return key;
    }

    flattenedKeys.push(key);
  }

  function _flattenKeys(keys, flattenedKeys) {
    keys.forEach(key => {
      flattenKey(key, flattenedKeys);
    });
  }

  function _default(keys) {
    var flattenedKeys = [];

    _flattenKeys(keys.slice(0, -1), flattenedKeys);

    var lastKey = keys[keys.length - 1];

    if (lastKey) {
      var lastValue = flattenKey(lastKey, flattenedKeys);

      if (lastValue) {
        if (lastValue.get) {
          flattenKey(lastValue.get, flattenedKeys);
        }

        if (lastValue.set) {
          flattenKey(lastValue.set, flattenedKeys);
        }
      }
    }

    return flattenedKeys;
  }
});