define("@zestia/ember-select-box/utils/select-box/selected-option/class-name", ["exports", "@zestia/ember-select-box/utils/shared/class-name"], function (_exports, _className) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildClassName;

  function buildClassName(selectedOption) {
    var classNames = [];
    var {
      selectBox
    } = selectedOption.args;
    classNames.push((0, _className.buildClassName)(selectBox, 'selected-option'));

    if (selectedOption.isActive) {
      classNames.push((0, _className.buildClassName)(selectBox, 'selected-option', 'active'));
    }

    return classNames.join(' ');
  }
});