define("@zestia/ember-select-box/utils/shared/selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isSelected;

  function isSelected(option) {
    if (option.args.selected !== undefined) {
      return option.args.selected;
    } else if (option.args.selectBox && option.args.selectBox.isMultiple) {
      return Ember.makeArray(option.args.selectBox.value).includes(option.value);
    } else if (option.args.selectBox) {
      return option.value === option.args.selectBox.value;
    } else {
      return false;
    }
  }
});