define("@zestia/ember-select-box/utils/select-box/selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildSelection = buildSelection;
  var {
    isArray
  } = Array;

  function buildSelection(selectBox, value1) {
    var value2 = selectBox.value;
    var build = selectBox.args.onBuildSelection;

    if (typeof build === 'function') {
      return build(value1, value2);
    }

    return buildSelectionDefault(selectBox, value1, value2);
  }

  function buildSelectionDefault(selectBox, value1, value2) {
    var value = value1;

    if (selectBox.isMultiple && !isArray(value1)) {
      var temp = Ember.A([...value2]);

      if (temp.includes(value1)) {
        temp.removeObject(value1);
      } else {
        temp.addObject(value1);
      }

      value = temp.toArray();
    }

    return value;
  }
});