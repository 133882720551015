define("ember-cookies/services/cookies", ["exports", "ember-cookies/utils/serialize-cookie"], function (_exports, _serializeCookie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var {
    keys
  } = Object;
  var assign = Object.assign || Ember.assign || Ember.merge;
  var DEFAULTS = {
    raw: false
  };
  var MAX_COOKIE_BYTE_LENGTH = 4096;

  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);

      this._document = this._document || window.document;

      if (typeof this._fastBoot === 'undefined') {
        var owner = Ember.getOwner(this);
        this._fastBoot = owner.lookup('service:fastboot');
      }
    },

    _getDocumentCookies() {
      var all = this._document.cookie.split(';');

      var filtered = this._filterDocumentCookies(all);

      return filtered.reduce((acc, cookie) => {
        if (!Ember.isEmpty(cookie)) {
          var [key, value] = cookie;
          acc[key.trim()] = (value || '').trim();
        }

        return acc;
      }, {});
    },

    _getFastBootCookies() {
      var fastBootCookies = Ember.get(this._fastBoot, 'request.cookies');
      fastBootCookies = keys(fastBootCookies).reduce((acc, name) => {
        var value = fastBootCookies[name];
        acc[name] = {
          value
        };
        return acc;
      }, {});
      var fastBootCookiesCache = this._fastBootCookiesCache || {};
      fastBootCookies = assign({}, fastBootCookies, fastBootCookiesCache);
      this._fastBootCookiesCache = fastBootCookies;
      return this._filterCachedFastBootCookies(fastBootCookies);
    },

    read(name, options = {}) {
      options = assign({}, DEFAULTS, options || {});
      (false && !(Ember.isEmpty(options.domain) && Ember.isEmpty(options.expires) && Ember.isEmpty(options.maxAge) && Ember.isEmpty(options.path)) && Ember.assert('Domain, Expires, Max-Age, and Path options cannot be set when reading cookies', Ember.isEmpty(options.domain) && Ember.isEmpty(options.expires) && Ember.isEmpty(options.maxAge) && Ember.isEmpty(options.path)));
      var all;

      if (this._isFastBoot()) {
        all = this._getFastBootCookies();
      } else {
        all = this._getDocumentCookies();
      }

      if (name) {
        return this._decodeValue(all[name], options.raw);
      } else {
        keys(all).forEach(name => all[name] = this._decodeValue(all[name], options.raw));
        return all;
      }
    },

    write(name, value, options = {}) {
      options = assign({}, DEFAULTS, options || {});
      (false && !(!options.signed) && Ember.assert("Cookies cannot be set as signed as signed cookies would not be modifyable in the browser as it has no knowledge of the express server's signing key!", !options.signed));
      (false && !(Ember.isEmpty(options.expires) || Ember.isEmpty(options.maxAge)) && Ember.assert('Cookies cannot be set with both maxAge and an explicit expiration time!', Ember.isEmpty(options.expires) || Ember.isEmpty(options.maxAge)));
      value = this._encodeValue(value, options.raw);
      (false && !(this._isCookieSizeAcceptable(value)) && Ember.assert("Cookies larger than ".concat(MAX_COOKIE_BYTE_LENGTH, " bytes are not supported by most browsers!"), this._isCookieSizeAcceptable(value)));

      if (this._isFastBoot()) {
        this._writeFastBootCookie(name, value, options);
      } else {
        (false && !(!options.httpOnly) && Ember.assert('Cookies cannot be set to be HTTP-only from a browser!', !options.httpOnly));
        options.path = options.path || this._normalizedDefaultPath();

        this._writeDocumentCookie(name, value, options);
      }
    },

    clear(name, options = {}) {
      options = assign({}, options || {});
      (false && !(Ember.isEmpty(options.expires) && Ember.isEmpty(options.maxAge) && Ember.isEmpty(options.raw)) && Ember.assert('Expires, Max-Age, and raw options cannot be set when clearing cookies', Ember.isEmpty(options.expires) && Ember.isEmpty(options.maxAge) && Ember.isEmpty(options.raw)));
      options.expires = new Date('1970-01-01');
      options.path = options.path || this._normalizedDefaultPath();
      this.write(name, null, options);
    },

    exists(name) {
      var all;

      if (this._isFastBoot()) {
        all = this._getFastBootCookies();
      } else {
        all = this._getDocumentCookies();
      }

      return all.hasOwnProperty(name);
    },

    _writeDocumentCookie(name, value, options = {}) {
      var serializedCookie = this._serializeCookie(name, value, options);

      this._document.cookie = serializedCookie;
    },

    _writeFastBootCookie(name, value, options = {}) {
      var responseHeaders = Ember.get(this._fastBoot, 'response.headers');

      var serializedCookie = this._serializeCookie(...arguments);

      if (!Ember.isEmpty(options.maxAge)) {
        options.maxAge *= 1000;
      }

      this._cacheFastBootCookie(...arguments);

      var replaced = false;
      var existing = responseHeaders.getAll('set-cookie');

      for (var i = 0; i < existing.length; i++) {
        if (existing[i].startsWith("".concat(name, "="))) {
          existing[i] = serializedCookie;
          replaced = true;
          break;
        }
      }

      if (!replaced) {
        responseHeaders.append('set-cookie', serializedCookie);
      }
    },

    _cacheFastBootCookie(name, value, options = {}) {
      var fastBootCache = this._fastBootCookiesCache || {};
      var cachedOptions = assign({}, options);

      if (cachedOptions.maxAge) {
        var expires = new Date();
        expires.setSeconds(expires.getSeconds() + options.maxAge);
        cachedOptions.expires = expires;
        delete cachedOptions.maxAge;
      }

      fastBootCache[name] = {
        value,
        options: cachedOptions
      };
      this._fastBootCookiesCache = fastBootCache;
    },

    _filterCachedFastBootCookies(fastBootCookies) {
      var {
        path: requestPath,
        protocol
      } = Ember.get(this._fastBoot, 'request'); // cannot use deconstruct here

      var host = Ember.get(this._fastBoot, 'request.host');
      return keys(fastBootCookies).reduce((acc, name) => {
        var {
          value,
          options
        } = fastBootCookies[name];
        options = options || {};
        var {
          path: optionsPath,
          domain,
          expires,
          secure
        } = options;

        if (optionsPath && requestPath.indexOf(optionsPath) !== 0) {
          return acc;
        }

        if (domain && host.indexOf(domain) + domain.length !== host.length) {
          return acc;
        }

        if (expires && expires < new Date()) {
          return acc;
        }

        if (secure && !(protocol || '').match(/^https/)) {
          return acc;
        }

        acc[name] = value;
        return acc;
      }, {});
    },

    _encodeValue(value, raw) {
      if (Ember.isNone(value)) {
        return '';
      } else if (raw) {
        return value;
      } else {
        return encodeURIComponent(value);
      }
    },

    _decodeValue(value, raw) {
      if (Ember.isNone(value) || raw) {
        return value;
      } else {
        return decodeURIComponent(value);
      }
    },

    _filterDocumentCookies(unfilteredCookies) {
      return unfilteredCookies.map(c => {
        var separatorIndex = c.indexOf('=');
        return [c.substring(0, separatorIndex), c.substring(separatorIndex + 1)];
      }).filter(c => c.length === 2 && Ember.isPresent(c[0]));
    },

    _serializeCookie(name, value, options = {}) {
      return (0, _serializeCookie.serializeCookie)(name, value, options);
    },

    _isCookieSizeAcceptable(value) {
      // Counting bytes varies Pre-ES6 and in ES6
      // This snippet counts the bytes in the value
      // about to be stored as the cookie:
      // See https://stackoverflow.com/a/25994411/6657064
      var _byteCount = 0;
      var i = 0;
      var c;

      while (c = value.charCodeAt(i++)) {
        /* eslint-disable no-bitwise */
        _byteCount += c >> 11 ? 3 : c >> 7 ? 2 : 1;
        /* eslint-enable no-bitwise */
      }

      return _byteCount < MAX_COOKIE_BYTE_LENGTH;
    },

    _normalizedDefaultPath() {
      if (!this._isFastBoot()) {
        var pathname = window.location.pathname;
        return pathname.substring(0, pathname.lastIndexOf('/'));
      }
    },

    _isFastBoot() {
      return this._fastBoot && this._fastBoot.isFastBoot;
    }

  });

  _exports.default = _default;
});