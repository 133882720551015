define("@zestia/ember-select-box/utils/select-box/input/keyboard", ["exports", "@zestia/ember-select-box/utils/component/invoke-action"], function (_exports, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.input = input;
  _exports.keyDown = keyDown;

  function input(input, e) {
    var text = input.element.value;

    if (!text) {
      clearedInput(input);
    }

    _input(input, text);

    inputText(input, text);
  }

  function _input(input, text) {
    (0, _invokeAction.default)(input, '_onInput', text);
  }

  function clearedInput(input) {
    (0, _invokeAction.default)(input, 'onClear', input.args.selectBox.api);
  }

  function inputText(input, text) {
    (0, _invokeAction.default)(input, 'onInput', text, input.args.selectBox.api);
  }

  function deletedText(input) {
    (0, _invokeAction.default)(input, 'onDelete', input.args.selectBox.api);
  }

  function keyDown(input, e) {
    if (e.keyCode === 8 && !input.element.value) {
      deletedText(input);
    }
  }
});