define("@zestia/ember-select-box/components/native-select-box/index", ["exports", "@glimmer/component", "@zestia/ember-select-box/utils/registration/element", "@zestia/ember-select-box/utils/registration/option", "@zestia/ember-select-box/utils/shared/value", "@zestia/ember-select-box/utils/native-select-box/value", "@zestia/ember-select-box/utils/shared/api", "@zestia/ember-select-box/utils/shared/class-name", "@zestia/ember-select-box/utils/shared/ready", "@zestia/ember-select-box/utils/shared/element"], function (_exports, _component, _element, _option, _value, _value2, _api, _className, _ready, _element2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <select
    class={{this.className}}
    multiple={{this.isMultiple}}
    ...attributes
    {{did-insert this.handleInsertElement}}
    {{did-update this.handleUpdateValue @value}}
    {{will-destroy this.handleDestroyElement}}
    {{on "change" this.handleChange}}
  >
    {{#let
      (hash
        Option=(component
          "native-select-box/option"
          selectBox=this
          _onInsert=this.handleInsertOption
          _onDestroy=this.handleDestroyOption
        )
        Group=(component "native-select-box/group" selectBox=this)
      ) as |components|
    }}
      {{yield (merge components this.api)}}
    {{/let}}
  </select>
  */
  {"id":"JEYW8Bh2","block":"{\"symbols\":[\"components\",\"&default\",\"&attrs\",\"@value\"],\"statements\":[[11,\"select\"],[16,0,[32,0,[\"className\"]]],[16,\"multiple\",[32,0,[\"isMultiple\"]]],[17,3],[4,[38,1],[[32,0,[\"handleInsertElement\"]]],null],[4,[38,2],[[32,0,[\"handleUpdateValue\"]],[32,4]],null],[4,[38,3],[[32,0,[\"handleDestroyElement\"]]],null],[4,[38,4],[\"change\",[32,0,[\"handleChange\"]]],null],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],null,[[\"Option\",\"Group\"],[[30,[36,5],[\"native-select-box/option\"],[[\"selectBox\",\"_onInsert\",\"_onDestroy\"],[[32,0],[32,0,[\"handleInsertOption\"]],[32,0,[\"handleDestroyOption\"]]]]],[30,[36,5],[\"native-select-box/group\"],[[\"selectBox\"],[[32,0]]]]]]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,2,[[30,[36,0],[[32,1],[32,0,[\"api\"]]],null]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"merge\",\"did-insert\",\"did-update\",\"will-destroy\",\"on\",\"component\",\"hash\",\"let\"]}","meta":{"moduleName":"@zestia/ember-select-box/components/native-select-box/index.hbs"}});

  var NativeSelectBox = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class NativeSelectBox extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "_api", {});

      _defineProperty(this, "pendingOptions", Ember.A());

      _defineProperty(this, "previousValue", null);

      _defineProperty(this, "valueID", 0);

      _initializerDefineProperty(this, "element", _descriptor, this);

      _initializerDefineProperty(this, "isFulfilled", _descriptor2, this);

      _initializerDefineProperty(this, "isPending", _descriptor3, this);

      _initializerDefineProperty(this, "isRejected", _descriptor4, this);

      _initializerDefineProperty(this, "isSettled", _descriptor5, this);

      _initializerDefineProperty(this, "options", _descriptor6, this);

      _initializerDefineProperty(this, "value", _descriptor7, this);

      (0, _value.receiveValue)(this);
    }

    get api() {
      return (0, _api.default)(this, ['element', 'isFulfilled', 'isMultiple', 'isPending', 'isRejected', 'isSettled', 'select', 'update', 'value']);
    }

    get className() {
      return (0, _className.buildClassName)(this);
    }

    get isMultiple() {
      return this.args.multiple;
    }

    handleInsertElement(element) {
      (0, _element.registerElement)(this, element);
      (0, _element2.insertElement)(this);
      (0, _ready.ready)(this);
    }

    handleDestroyElement() {
      (0, _element.deregisterElement)(this);
    }

    handleUpdateValue() {
      (0, _value.receiveValue)(this);
    }

    handleInsertOption(option) {
      (0, _option.registerOption)(this, option);
    }

    handleDestroyOption(option) {
      (0, _option.deregisterOption)(this, option);
    }

    handleChange() {
      (0, _value2.selectValue)(this);
    }

    select(value) {
      return (0, _value.selectValue)(this, value);
    }

    update(value) {
      return (0, _value.updateValue)(this, value);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "element", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isFulfilled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isPending", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isRejected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isSettled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleUpdateValue", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "handleUpdateValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInsertOption", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyOption", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class));
  _exports.default = NativeSelectBox;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NativeSelectBox);
});