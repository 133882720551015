define("@zestia/ember-select-box/utils/select-box/keyboard", ["exports", "@zestia/ember-select-box/utils/component/invoke-action", "@zestia/ember-select-box/utils/select-box/option/select"], function (_exports, _invokeAction, _select) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.keyPress = keyPress;
  _exports.keyDown = keyDown;
  _exports.pressEnter = pressEnter;
  _exports.shouldPreventDefault = shouldPreventDefault;
  _exports.keys = void 0;
  var keys = {
    8: 'backspace',
    9: 'tab',
    13: 'enter',
    27: 'escape',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down'
  };
  _exports.keys = keys;

  function keyPress(selectBox, e) {
    pressedKey(selectBox, e);
  }

  function keyDown(selectBox, e) {
    var key = keys[e.keyCode];

    if (key) {
      key = Ember.String.capitalize(key);

      _keyedDown(selectBox, key, e);

      keyedDown(selectBox, key, e);
    }
  }

  function pressedKey(selectBox, e) {
    (0, _invokeAction.default)(selectBox, 'onPressKey', e, selectBox.api);
  }

  function _keyedDown(selectBox, key, e) {
    var name = "handlePress".concat(key);
    var func = selectBox[name];

    if (typeof func === 'function') {
      func(e);
    }
  }

  function keyedDown(selectBox, key, e) {
    (0, _invokeAction.default)(selectBox, "onPress".concat(key), e, selectBox.api);
  }

  function pressEnter(selectBox, e) {
    if (!selectBox.activeOption) {
      return;
    }

    if (shouldPreventDefault(selectBox, e)) {
      e.preventDefault();
    }

    (0, _select._selectOption)(selectBox.activeOption);
  }

  function shouldPreventDefault(selectBox, e) {
    return selectBox.activeOption && selectBox.input && e.target === selectBox.input.element;
  }
});