define("@zestia/ember-select-box/utils/select-box/class-name", ["exports", "@zestia/ember-select-box/utils/shared/class-name"], function (_exports, _className) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildClassName;

  function buildClassName(selectBox) {
    var classNames = [];
    classNames.push((0, _className.buildClassName)(selectBox));

    if (selectBox.isOpen) {
      classNames.push((0, _className.buildClassName)(selectBox, null, 'open'));
    }

    if (selectBox.isFocused) {
      classNames.push((0, _className.buildClassName)(selectBox, null, 'focused'));
    }

    if (selectBox.isDisabled) {
      classNames.push((0, _className.buildClassName)(selectBox, null, 'disabled'));
    }

    if (selectBox.isMultiple) {
      classNames.push((0, _className.buildClassName)(selectBox, null, 'multiple'));
    }

    if (selectBox.isBusy) {
      classNames.push((0, _className.buildClassName)(selectBox, null, 'busy'));
    }

    return classNames.join(' ');
  }
});