define("@zestia/ember-select-box/utils/select-box/option/class-name", ["exports", "@zestia/ember-select-box/utils/shared/class-name"], function (_exports, _className) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildClassName;

  function buildClassName(option) {
    var classNames = [];
    var {
      selectBox
    } = option.args;
    classNames.push((0, _className.buildClassName)(selectBox, 'option'));

    if (option.isActive) {
      classNames.push((0, _className.buildClassName)(selectBox, 'option', 'active'));
    }

    if (option.isDisabled) {
      classNames.push((0, _className.buildClassName)(selectBox, 'option', 'disabled'));
    }

    if (option.isSelected) {
      classNames.push((0, _className.buildClassName)(selectBox, 'option', 'selected'));
    }

    return classNames.join(' ');
  }
});