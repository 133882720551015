define("ember-leaflet/templates/lt-2-3/div-overlayable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zWBFNgrq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-leaflet/templates/lt-2-3/div-overlayable.hbs"
    }
  });

  _exports.default = _default;
});