define("@zestia/ember-select-box/components/select-box/options/index", ["exports", "@glimmer/component", "@zestia/ember-select-box/utils/component/lifecycle", "@zestia/ember-select-box/utils/shared/class-name"], function (_exports, _component, _lifecycle, _className) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    data-component="options"
    class={{this.className}}
    aria-activedescendant={{@aria-activedescendant}}
    {{! Issue: https://github.com/emberjs/rfcs/issues/497 }}
    {{! template-lint-disable no-invalid-interactive }}
    onclick={{@onclick}}
    ...attributes
    {{did-insert this.handleInsertElement}}
    {{will-destroy this.handleDestroyElement}}
  >
    {{yield}}
  </div>
  */
  {"id":"I2qxagm0","block":"{\"symbols\":[\"@aria-activedescendant\",\"@onclick\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,\"data-component\",\"options\"],[16,0,[32,0,[\"className\"]]],[16,\"aria-activedescendant\",[32,1]],[16,\"onclick\",[32,2]],[17,3],[4,[38,0],[[32,0,[\"handleInsertElement\"]]],null],[4,[38,1],[[32,0,[\"handleDestroyElement\"]]],null],[12],[2,\"\\n  \"],[18,4,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"will-destroy\"]}","meta":{"moduleName":"@zestia/ember-select-box/components/select-box/options/index.hbs"}});

  var SelectBoxOptions = (_dec = Ember._action, _dec2 = Ember._action, (_class = class SelectBoxOptions extends _component.default {
    get className() {
      return (0, _className.buildClassName)(this.args.selectBox, 'options');
    }

    handleInsertElement() {
      (0, _lifecycle._insertComponent)(this);
    }

    handleDestroyElement() {
      (0, _lifecycle._destroyComponent)(this);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype)), _class));
  _exports.default = SelectBoxOptions;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SelectBoxOptions);
});