define("ember-intl/-private/formatters/-format-datetime", ["exports", "fast-memoize", "ember-intl/-private/formatters/-base"], function (_exports, _fastMemoize, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var DATE_TIME_OPTIONS = ['localeMatcher', 'formatMatcher', 'timeZone', 'hour12', 'weekday', 'era', 'year', 'month', 'day', 'hour', 'minute', 'second', 'timeZoneName', 'hourCycle'];
  /**
   * @private
   * @hide
   */

  class FormatDateTime extends _base.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "createNativeFormatter", (0, _fastMemoize.default)((locales, options) => {
        return new Intl.DateTimeFormat(locales, options);
      }));
    }

    get options() {
      return DATE_TIME_OPTIONS;
    }

    format(locale, value, formatOptions) {
      var formatterOptions = this.readOptions(formatOptions);
      this.validateFormatterOptions(locale, formatterOptions);
      var formatterInstance = this.createNativeFormatter(locale, formatterOptions);
      return formatterInstance.format(new Date(value));
    }

  }

  _exports.default = FormatDateTime;

  _defineProperty(FormatDateTime, "type", void 0);
});