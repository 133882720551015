define("ember-leaflet-tiles-provider/components/tile-layer", ["exports", "ember-leaflet/components/tile-layer"], function (_exports, _tileLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tileLayer.default.extend({
    // needed for access to paid maps
    leafletProviderOptions: [// eslint-disable-line
    'apiKey', // Thunderforest
    'accessToken', // Mapbox
    'app_id', 'app_code' // HERE (Formerly Nokia maps)
    ],

    createProviderLayer(provider) {
      this.set('leafletRequiredOptions', ['provider']);
      var leafletOptions = this.get('leafletOptions');
      var leafletProviderOptions = this.get('leafletProviderOptions');
      this.set('leafletOptions', Object.assign([], leafletOptions, leafletProviderOptions));
      return this.L.tileLayer.provider(provider, this.get('options'));
    },

    createLayer() {
      var provider = this.get('provider');
      return !provider ? this._super(...arguments) : this.createProviderLayer(provider);
    }

  });

  _exports.default = _default;
});