define("ember-composable-helpers/helpers/has-next", ["exports", "ember-composable-helpers/helpers/next", "ember-composable-helpers/utils/is-equal", "ember-composable-helpers/-private/get-value-array-and-use-deep-equal-from-params", "ember-composable-helpers/utils/as-array"], function (_exports, _next, _isEqual, _getValueArrayAndUseDeepEqualFromParams, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasNext = hasNext;
  _exports.default = void 0;

  function hasNext(currentValue, maybeArray, useDeepEqual = false) {
    var array = (0, _asArray.default)(maybeArray);
    var nextValue = (0, _next.next)(currentValue, array, useDeepEqual);
    var isNotSameValue = !(0, _isEqual.default)(nextValue, currentValue, useDeepEqual);
    return isNotSameValue && Ember.isPresent(nextValue);
  }

  var _default = Ember.Helper.helper(function (params) {
    var {
      currentValue,
      array,
      useDeepEqual
    } = (0, _getValueArrayAndUseDeepEqualFromParams.default)(params);
    return hasNext(currentValue, array, useDeepEqual);
  });

  _exports.default = _default;
});