define("ember-composable-helpers/helpers/join", ["exports", "ember-composable-helpers/utils/as-array"], function (_exports, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.join = join;
  _exports.default = void 0;

  function join([separator, rawArray]) {
    var array = (0, _asArray.default)(rawArray);

    if (Ember.isArray(separator)) {
      array = separator;
      separator = ',';
    }

    return array.join(separator);
  }

  var _default = Ember.Helper.helper(join);

  _exports.default = _default;
});