define("@sentry/ember/instance-initializers/sentry-performance", ["exports", "ember-get-config", "@sentry/browser", "@sentry/ember", "@sentry/utils"], function (_exports, _emberGetConfig, _browser, _ember, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports._instrumentEmberRouter = _instrumentEmberRouter;
  _exports.instrumentForPerformance = instrumentForPerformance;
  _exports.default = void 0;

  function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

  function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

  function initialize(appInstance) {
    var config = _emberGetConfig.default['@sentry/ember'];

    if (config['disablePerformance']) {
      return;
    }

    var performancePromise = instrumentForPerformance(appInstance);
  }

  function getTransitionInformation(transition, router) {
    var _transition$from;

    var fromRoute = transition === null || transition === void 0 ? void 0 : (_transition$from = transition.from) === null || _transition$from === void 0 ? void 0 : _transition$from.name;
    var toRoute = transition && transition.to ? transition.to.name : router.currentRouteName;
    return {
      fromRoute,
      toRoute
    };
  }

  function _instrumentEmberRouter(routerService, routerMain, config, startTransaction, startTransactionOnPageLoad) {
    var {
      disableRunloopPerformance
    } = config;
    var location = routerMain.location;
    var activeTransaction;
    var transitionSpan;
    var url = location && location.getURL && location.getURL();

    if (startTransactionOnPageLoad && url) {
      var routeInfo = routerService.recognize(url);
      activeTransaction = startTransaction({
        name: "route:".concat(routeInfo.name),
        op: 'pageload',
        tags: {
          url,
          toRoute: routeInfo.name,
          'routing.instrumentation': '@sentry/ember'
        }
      });
    }

    var finishActiveTransaction = function (_, nextInstance) {
      if (nextInstance) {
        return;
      }

      activeTransaction.finish();
      Ember.run.backburner.off('end', finishActiveTransaction);
    };

    routerService.on('routeWillChange', transition => {
      var _activeTransaction;

      var {
        fromRoute,
        toRoute
      } = getTransitionInformation(transition, routerService);
      (_activeTransaction = activeTransaction) === null || _activeTransaction === void 0 ? void 0 : _activeTransaction.finish();
      activeTransaction = startTransaction({
        name: "route:".concat(toRoute),
        op: 'navigation',
        tags: {
          fromRoute,
          toRoute,
          'routing.instrumentation': '@sentry/ember'
        }
      });
      transitionSpan = activeTransaction.startChild({
        op: 'ember.transition',
        description: "route:".concat(fromRoute, " -> route:").concat(toRoute)
      });
    });
    routerService.on('routeDidChange', () => {
      if (!transitionSpan || !activeTransaction) {
        return;
      }

      transitionSpan.finish();

      if (disableRunloopPerformance) {
        activeTransaction.finish();
        return;
      }

      Ember.run.backburner.on('end', finishActiveTransaction);
    });
    return {
      startTransaction
    };
  }

  function _instrumentEmberRunloop(config) {
    var {
      disableRunloopPerformance,
      minimumRunloopQueueDuration
    } = config;

    if (disableRunloopPerformance) {
      return;
    }

    var currentQueueStart;
    var currentQueueSpan;
    var instrumentedEmberQueues = ['actions', 'routerTransitions', 'render', 'afterRender', 'destroy'];
    Ember.run.backburner.on('begin', (_, previousInstance) => {
      if (previousInstance) {
        return;
      }

      var activeTransaction = (0, _ember.getActiveTransaction)();

      if (!activeTransaction) {
        return;
      }

      if (currentQueueSpan) {
        currentQueueSpan.finish();
      }

      currentQueueStart = (0, _utils.timestampWithMs)();
      instrumentedEmberQueues.forEach(queue => {
        Ember.run.scheduleOnce(queue, null, () => {
          Ember.run.scheduleOnce(queue, null, () => {
            // Process this queue using the end of the previous queue.
            if (currentQueueStart) {
              var now = (0, _utils.timestampWithMs)();
              var minQueueDuration = minimumRunloopQueueDuration !== null && minimumRunloopQueueDuration !== void 0 ? minimumRunloopQueueDuration : 5;

              if ((now - currentQueueStart) * 1000 >= minQueueDuration) {
                activeTransaction === null || activeTransaction === void 0 ? void 0 : activeTransaction.startChild({
                  op: "ember.runloop.".concat(queue),
                  startTimestamp: currentQueueStart,
                  endTimestamp: now
                }).finish();
              }

              currentQueueStart = undefined;
            } // Setup for next queue


            var stillActiveTransaction = (0, _ember.getActiveTransaction)();

            if (!stillActiveTransaction) {
              return;
            }

            currentQueueStart = (0, _utils.timestampWithMs)();
          });
        });
      });
    });
    Ember.run.backburner.on('end', (_, nextInstance) => {
      if (nextInstance) {
        return;
      }

      if (currentQueueSpan) {
        currentQueueSpan.finish();
        currentQueueSpan = undefined;
      }
    });
  }

  function processComponentRenderBefore(payload, beforeEntries) {
    var info = {
      payload,
      now: (0, _utils.timestampWithMs)()
    };
    beforeEntries[payload.object] = info;
  }

  function processComponentRenderAfter(payload, beforeEntries, op, minComponentDuration) {
    var begin = beforeEntries[payload.object];

    if (!begin) {
      return;
    }

    var now = (0, _utils.timestampWithMs)();
    var componentRenderDuration = now - begin.now;

    if (componentRenderDuration * 1000 >= minComponentDuration) {
      var activeTransaction = (0, _ember.getActiveTransaction)();
      activeTransaction === null || activeTransaction === void 0 ? void 0 : activeTransaction.startChild({
        op,
        description: payload.containerKey || payload.object,
        startTimestamp: begin.now,
        endTimestamp: now
      });
    }
  }

  function _instrumentComponents(config) {
    var {
      disableInstrumentComponents,
      minimumComponentRenderDuration,
      enableComponentDefinitions
    } = config;

    if (disableInstrumentComponents) {
      return;
    }

    var minComponentDuration = minimumComponentRenderDuration !== null && minimumComponentRenderDuration !== void 0 ? minimumComponentRenderDuration : 2;
    var beforeEntries = {};
    var beforeComponentDefinitionEntries = {};
    var subscribe = Ember.subscribe;

    function _subscribeToRenderEvents() {
      subscribe('render.component', {
        before(_name, _timestamp, payload) {
          processComponentRenderBefore(payload, beforeEntries);
        },

        after(_name, _timestamp, payload, _beganIndex) {
          processComponentRenderAfter(payload, beforeEntries, 'ember.component.render', minComponentDuration);
        }

      });

      if (enableComponentDefinitions) {
        subscribe('render.getComponentDefinition', {
          before(_name, _timestamp, payload) {
            processComponentRenderBefore(payload, beforeComponentDefinitionEntries);
          },

          after(_name, _timestamp, payload, _beganIndex) {
            processComponentRenderAfter(payload, beforeComponentDefinitionEntries, 'ember.component.definition', 0);
          }

        });
      }
    }

    _subscribeToRenderEvents();
  }

  function _instrumentInitialLoad(config) {
    var startName = '@sentry/ember:initial-load-start';
    var endName = '@sentry/ember:initial-load-end';
    var {
      performance
    } = window;
    var HAS_PERFORMANCE = performance && performance.clearMarks && performance.clearMeasures;

    if (!HAS_PERFORMANCE) {
      return;
    }

    if (config.disableInitialLoadInstrumentation) {
      performance.clearMarks(startName);
      performance.clearMarks(endName);
      return;
    } // Split performance check in two so clearMarks still happens even if timeOrigin isn't available.


    var HAS_PERFORMANCE_TIMING = performance.measure && performance.getEntriesByName && performance.timeOrigin !== undefined;

    if (!HAS_PERFORMANCE_TIMING) {
      return;
    }

    var measureName = '@sentry/ember:initial-load';
    performance.measure(measureName, startName, endName);
    var measures = performance.getEntriesByName(measureName);
    var measure = measures[0];
    var startTimestamp = (measure.startTime + performance.timeOrigin) / 1000;
    var endTimestamp = startTimestamp + measure.duration / 1000;
    var transaction = (0, _ember.getActiveTransaction)();
    var span = transaction === null || transaction === void 0 ? void 0 : transaction.startChild({
      op: 'ember.initial-load',
      startTimestamp
    });
    span === null || span === void 0 ? void 0 : span.finish(endTimestamp);
    performance.clearMarks(startName);
    performance.clearMarks(endName);
    performance.clearMeasures(measureName);
  }

  function instrumentForPerformance(_x) {
    return _instrumentForPerformance.apply(this, arguments);
  }

  function _instrumentForPerformance() {
    _instrumentForPerformance = _asyncToGenerator(function* (appInstance) {
      var _Sentry$getCurrentHub;

      var config = _emberGetConfig.default['@sentry/ember'];
      var sentryConfig = config.sentry;
      var tracing = yield emberAutoImportDynamic("@sentry/tracing");
      var idleTimeout = config.transitionTimeout || 5000;
      var existingIntegrations = sentryConfig['integrations'] || [];
      sentryConfig['integrations'] = [...existingIntegrations, new tracing.Integrations.BrowserTracing({
        routingInstrumentation: (startTransaction, startTransactionOnPageLoad) => {
          var routerMain = appInstance.lookup('router:main');
          var routerService = appInstance.lookup('service:router');

          _instrumentEmberRouter(routerService, routerMain, config, startTransaction, startTransactionOnPageLoad);
        },
        idleTimeout
      })];

      if (false && ((_Sentry$getCurrentHub = _browser.default.getCurrentHub()) === null || _Sentry$getCurrentHub === void 0 ? void 0 : _Sentry$getCurrentHub.getIntegration(tracing.Integrations.BrowserTracing))) {
        // Initializers are called more than once in tests, causing the integrations to not be setup correctly.
        return;
      }

      _browser.default.init(sentryConfig); // Call init again to rebind client with new integration list in addition to the defaults


      _instrumentEmberRunloop(config);

      _instrumentComponents(config);

      _instrumentInitialLoad(config);
    });
    return _instrumentForPerformance.apply(this, arguments);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});