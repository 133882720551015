define("ember-modifier/-private/class/modifier-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function destroyModifier(modifier) {
    modifier.willRemove();
    modifier.willDestroy();
  }

  class ClassBasedModifierManager {
    constructor(owner) {
      this.owner = owner;

      _defineProperty(this, "capabilities", Ember._modifierManagerCapabilities('3.13'));
    }

    createModifier(factory, args) {
      var Modifier = factory.class;
      var modifier = new Modifier(this.owner, args);

      Ember._registerDestructor(modifier, destroyModifier);

      return modifier;
    }

    installModifier(instance, element) {
      instance.element = element;
      instance.didReceiveArguments();
      instance.didInstall();
    }

    updateModifier(instance, args) {
      // TODO: this should be an args proxy
      Ember.set(instance, 'args', args);
      instance.didUpdateArguments();
      instance.didReceiveArguments();
    }

    destroyModifier(instance) {
      Ember.destroy(instance);
    }

  }

  _exports.default = ClassBasedModifierManager;
});