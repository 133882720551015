define("ember-leaflet/templates/current/leaflet-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yom7E0AF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"tile\",\"wms-tile\",\"marker\",\"circle\",\"circle-marker\",\"image\",\"video\",\"polyline\",\"polygon\",\"geojson\",\"wmsTile\",\"geoJSON\"],[[30,[36,0],[\"tile-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"wms-tile-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"marker-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"circle-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"circle-marker-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"image-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"video-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"polyline-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"polygon-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"geojson-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"wms-tile-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"geojson-layer\"],[[\"parentComponent\"],[[32,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-leaflet/templates/current/leaflet-map.hbs"
    }
  });

  _exports.default = _default;
});