define("@zestia/ember-select-box/utils/select-box/focus", ["exports", "@zestia/ember-select-box/utils/component/invoke-action"], function (_exports, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.focusIn = focusIn;
  _exports.focusOut = focusOut;

  function focusIn(selectBox, e) {
    try {
      selectBox.isFocused = true;
    } catch (error) {// https://github.com/emberjs/ember.js/issues/18043
    } finally {
      focusedIn(selectBox, e);
    }
  }

  function focusOut(selectBox, e) {
    try {
      selectBox.isFocused = false;
    } catch (error) {// https://github.com/emberjs/ember.js/issues/18043
    } finally {
      focusedOut(selectBox, e);
    }
  }

  function focusedIn(selectBox, e) {
    (0, _invokeAction.default)(selectBox, 'onFocusIn', e, selectBox.api);
  }

  function focusedOut(selectBox, e) {
    (0, _invokeAction.default)(selectBox, 'onFocusOut', e, selectBox.api);
  }
});