define("@zestia/ember-select-box/utils/select-box/toggle", ["exports", "@zestia/ember-select-box/utils/component/invoke-action"], function (_exports, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.open = open;
  _exports.close = close;
  _exports.toggle = toggle;

  function open(selectBox) {
    if (selectBox.isOpen) {
      return;
    }

    selectBox.isOpen = true;
    opened(selectBox);
  }

  function close(selectBox) {
    if (!selectBox.isOpen) {
      return;
    }

    selectBox.isOpen = false;
    closed(selectBox);
  }

  function opened(selectBox) {
    (0, _invokeAction.default)(selectBox, 'onOpen', selectBox.api);
  }

  function closed(selectBox) {
    (0, _invokeAction.default)(selectBox, 'onClose', selectBox.api);
  }

  function toggle(selectBox) {
    if (selectBox.isOpen) {
      close(selectBox);
    } else {
      open(selectBox);
    }
  }
});