define("ember-leaflet/templates/leaflet-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w9zXnxef",
    "block": "{\"symbols\":[\"components\",\"c\",\"&default\"],\"statements\":[[6,[37,6],[[30,[36,5],null,[[\"tile\",\"wms-tile\",\"marker\",\"circle\",\"circle-marker\",\"image\",\"video\",\"polyline\",\"polygon\",\"geojson\",\"rectangle\",\"wmsTile\",\"geoJSON\"],[[30,[36,0],[\"tile-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"wms-tile-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"marker-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"circle-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"circle-marker-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"image-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"video-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"polyline-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"polygon-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"geojson-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"rectangle-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"wms-tile-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"geojson-layer\"],[[\"parentComponent\"],[[32,0]]]]]]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[32,0,[\"emberLeaflet\",\"components\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"emberLeaflet\",\"components\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],[[32,1]],[[\"key\",\"value\",\"onChange\"],[[32,2,[\"as\"]],[30,[36,0],[[32,2,[\"name\"]]],[[\"parentComponent\"],[[32,0]]]],[32,0,[\"mergeComponents\"]]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[18,3,[[32,0,[\"mergedComponents\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,3,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"ember-leaflet-assign-to\",\"-track-array\",\"each\",\"if\",\"hash\",\"let\"]}",
    "meta": {
      "moduleName": "ember-leaflet/templates/leaflet-map.hbs"
    }
  });

  _exports.default = _default;
});