define("@zestia/ember-select-box/utils/shared/class-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildClassName = buildClassName;

  function buildClassName(selectBox, child, state) {
    var build = selectBox && selectBox.args.onBuildClassName;

    if (typeof build === 'function') {
      return build(child, state);
    }

    var prefix = 'select-box';

    if (selectBox && selectBox.args.classNamePrefix) {
      prefix = selectBox.args.classNamePrefix;
    }

    return buildClassNameDefault(prefix, child, state);
  }

  function buildClassNameDefault(prefix, child, state) {
    var className = prefix;

    if (child) {
      className = "".concat(className, "__").concat(child);
    }

    if (state) {
      className = "".concat(className, "--").concat(state);
    }

    return className;
  }
});