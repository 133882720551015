define("@zestia/ember-select-box/utils/shared/value", ["exports", "@zestia/ember-select-box/utils/component/invoke-action", "@zestia/ember-select-box/utils/component/value"], function (_exports, _invokeAction, _value) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.receiveValue = receiveValue;
  _exports.updateValue = updateValue;
  _exports.selectValue = selectValue;
  _exports.selectedValue = selectedValue;
  var {
    freeze
  } = Object;

  function receiveValue(selectBox) {
    updateValue(selectBox, selectBox.args.value);
  }

  function updateValue(selectBox, value) {
    return (0, _value.resolveValue)(selectBox, value, processValue).then(() => updatedValue(selectBox));
  }

  function selectValue(selectBox, value) {
    return updateValue(selectBox, value).then(() => selectedValue(selectBox));
  }

  function processValue(selectBox, value) {
    if (selectBox.isMultiple) {
      return freeze(Ember.makeArray(value).slice());
    }

    return value;
  }

  function updatedValue(selectBox) {
    if (selectBox.value === selectBox.previousValue) {
      return;
    }

    (0, _invokeAction.default)(selectBox, 'onUpdate', selectBox.api);
  }

  function selectedValue(selectBox) {
    (0, _invokeAction.default)(selectBox, 'onSelect', selectBox.value, selectBox.api);
  }
});