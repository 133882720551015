define("ember-leaflet/templates/current/div-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A1IYuz2w",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,5]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,1,[[30,[36,1],[[32,0],[35,0]],null]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"closePopup\",\"action\",\"if\",\"destinationElement\",\"in-element\",\"shouldRender\",\"isFastBoot\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-leaflet/templates/current/div-overlay.hbs"
    }
  });

  _exports.default = _default;
});