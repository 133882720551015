define("ember-leaflet/templates/div-overlayable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ki02IK7P",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"popup\",\"tooltip\"],[[30,[36,0],[\"popup-layer\"],[[\"parentComponent\"],[[32,0]]]],[30,[36,0],[\"tooltip-layer\"],[[\"parentComponent\"],[[32,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-leaflet/templates/div-overlayable.hbs"
    }
  });

  _exports.default = _default;
});